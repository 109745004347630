import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { AddIcon, LoaderIcon, RemoveICon } from "../../Shared/Icons";
import { IconButton } from "@mui/material";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import BootstrapModal from "react-bootstrap/Modal";
import PermissionComponent from "../../Shared/Common/Permissions/PermissionComponent";
import FacilityService from "../../Services/FacilityService/FacilityService";
import { AxiosError, AxiosResponse } from "axios";
import ManageSalesRepServices from "../../Services/ManageSalesRep/ManageSalesRepServices";
import UserManagementService from "../../Services/UserManagement/UserManagementService";
import { toast } from "react-toastify";
import useLang from "Shared/hooks/useLanguage";
interface Props {
  Edit: any;
  item: any;
  handleClose: any;
  DeleteSpecimenTypeAssignmentById: any;
  statusChange: any;
  panels: any;
  sports2: any;
  row: any;
  setSports2: any;
  setPanels: any;
  loadData: any;
  setSelectedPanels: any;
  selectedPanels: any;
  check: any;
  setOpen: any;
  val: any;
}
const FacilityListExpandableTable: React.FC<any> = ({
  item,
  Edit,
  handleClose,
  DeleteSpecimenTypeAssignmentById,
  statusChange,
  panels,
  sports2,
  setSports2,
  setPanels,
  loadData,
  setSelectedPanels,
  selectedPanels,
  row,
  check,
  setOpen,
  val,
}) => {
  const { t } = useLang();

  function capitalizeFirstLetter(str: any) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const [open1, setOpen1] = React.useState(false);
  const [openalert, setOpenAlert] = React.useState(false);
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleClickOpen = () => {
    setOpenAlert(true);
  };
  const [userList, setUserList] = useState<any>([]);
  const getSelectedFacilityData = async () => {
    await FacilityService.ViewAssignedFacilities(item?.id).then(
      (res: AxiosResponse) => {
        setUserList(res?.data?.data);
      }
    );
  };
  const ArchivedUser = async (id: any) => {
    await ManageSalesRepServices.ArchiveUser(id).then((res: AxiosResponse) => {
      if (res.data.statusCode === 200) {
        handleCloseAlert();
        loadData();
      }
    });
  };
  const TokenForReset = (Email: string) => {
    UserManagementService?.TokenForResetPassword(Email, "lab")
      .then((res: AxiosResponse) => {
        if (res?.data?.statusCode === 200) {
          toast.success("Email Sent Successfully");
        } else if (res?.data?.statusCode === 400) {
          toast.error(res?.data?.message);
        }
      })
      .catch((err: AxiosError) => {});
  };
  return (
    <>
      <TableRow>
        {val === 1 ? null : (
          <TableCell className="text-center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen1(!open1)}
              className="btn btn-icon btn-icon-light btn-sm fw-bold btn-table-expend-row rounded h-20px w-20px"
            >
              {open1 ? (
                <button className="btn btn-icon btn-icon-light btn-sm fw-bold rounded h-10px w-20px">
                  <RemoveICon />
                </button>
              ) : (
                <button
                  className="btn btn-icon btn-icon-light btn-sm fw-bold btn-primary rounded h-20px w-20px min-h-20px"
                  onClick={getSelectedFacilityData}
                >
                  <AddIcon />
                </button>
              )}
            </IconButton>
          </TableCell>
        )}
        {val === 1 ? null : (
          <TableCell className="text-center">
            <div className="rotatebtnn">
              <DropdownButton
                className="p-0 del-before btn btn-light-info btn-active-info btn-sm btn-action table-action-btn"
                key="end"
                id="dropdown-button-drop-end"
                drop="end"
                title={<i className="bi bi-three-dots-vertical p-0"></i>}
              >
                <>
                  <PermissionComponent
                    moduleName="Manage Sales Rep"
                    pageName="Sales Rep User"
                    permissionIdentifier="Edit"
                  >
                    <Dropdown.Item eventKey="2">
                      <div className="menu-item px-3">
                        <i className="fa fa-user text-info mr-2 w-20px"></i>
                        {t("Go to Portal")}
                      </div>
                    </Dropdown.Item>
                  </PermissionComponent>
                  <PermissionComponent
                    moduleName="Manage Sales Rep"
                    pageName="Sales Rep User"
                    permissionIdentifier="Edit"
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() => {
                        Edit(item);
                        handleClose();
                        setOpen(false);
                      }}
                    >
                      <div className="menu-item px-3">
                        <i className="fa fa-edit text-primary mr-2 w-20px"></i>
                        {t("Edit")}
                      </div>
                    </Dropdown.Item>
                  </PermissionComponent>
                  <PermissionComponent
                    moduleName="Manage Sales Rep"
                    pageName="Sales Rep User"
                    permissionIdentifier="Archived"
                  >
                    <Dropdown.Item eventKey="2" onClick={handleClickOpen}>
                      <div className="menu-item px-3">
                        <i className="fa fa-archive mr-2 text-success"></i>
                        {t("Archived")}
                      </div>
                    </Dropdown.Item>
                  </PermissionComponent>
                  <PermissionComponent
                    moduleName="Manage Sales Rep"
                    pageName="Sales Rep User"
                    permissionIdentifier="Reset"
                  >
                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => TokenForReset(item.salesRepEmail)}
                    >
                      <div className="menu-item px-3">
                        <i className="fa fa-key text-warning mr-2 w-20px"></i>
                        {t("Reset")}
                      </div>
                    </Dropdown.Item>
                  </PermissionComponent>
                </>
              </DropdownButton>
            </div>
          </TableCell>
        )}
        <TableCell>{item?.firstName}</TableCell>
        <TableCell>{item?.lastName}</TableCell>
        <TableCell>{item.salesRepEmail}</TableCell>
        <TableCell>{item.salesRepPhone}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={7} className="padding-0">
          <Collapse in={open1} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography gutterBottom component="div">
                <div
                  className="col-xl-12 col-lg-12 col-md-12 col-sm-12 rounded"
                  style={{ border: "1px solid rgb(114, 57, 234)" }}
                >
                  <div className="card mb-4 border">
                    <div className="card-header bg-light-info d-flex justify-content-between align-items-center px-4 min-h-40px">
                      <h6 className="m-0 text-dark">
                        {t("Assigned Facilities")}
                      </h6>
                    </div>
                    <div className="card-body py-md-4 py-3 px-4">
                      <div className="row">
                        <div className="text-primary fw-700">
                          {t("Facility Name")}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12 d-flex flex-wrap gap-2">
                          {userList?.map((i: any) => (
                            <div className="badge badge-secondary round-2 py-2 my-2 fs-7">
                              {capitalizeFirstLetter(i.facilityName)}
                              {"-"}
                              {i.address}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <BootstrapModal
        show={openalert}
        onHide={handleCloseAlert}
        backdrop="static"
        keyboard={false}
      >
        <BootstrapModal.Header closeButton className="bg-light-primary m-0 p-5">
          <h4>{t("Archive Menu")}</h4>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {t("Are you sure you want to archive?")}
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="p-0">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseAlert}
          >
            {t("Cancel")}
          </button>
          <button
            type="button"
            className="btn btn-danger m-2"
            onClick={() => ArchivedUser(item?.id)}
          >
            <span>{check ? <LoaderIcon /> : null}</span>
            <span>
              {""} {t("Archive")}
            </span>
          </button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};
export default FacilityListExpandableTable;
