import { SetStateAction, useEffect, useRef } from "react";
import useAutocomplete from "./useAutocomplete";
import { assignFormValues } from "../../Utils/Auth";
import OutsiderAlert from "../../Shared/OutsiderAlert";
import Splash from "../Common/Pages/Splash";
import { useLocation } from "react-router-dom";
import { t } from "i18next";

const Autocomplete = (props: any) => {
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const inputElementReactSelect = useRef(props?.sysytemFieldName);

  useEffect(() => {
    // if (inputSearchRef.current) {
    //   inputSearchRef.current.focus();
    // }
    getApiCallName();
  }, [props.value]);

  useEffect(() => {
    clearInput();
  }, [props?.inputEmpty]);
  const {
    searchedValue,
    setSearchedValue,
    setapiCallDetails,
    setTouched,
    setActiveSuggestion,
    setSelectedSuggestion,
    suggestions,
    setSuggestions,
    selectedSuggestion,
    activeSuggestion,
    loading,
    handleChange,
    handleKeyDown,
    handleClick,
    touched,
  } = useAutocomplete(inputSearchRef.current);
  const clearInput = () => {
    setSearchedValue("");
  };
  useEffect(() => {
    setPatienntFirstNameLastName();
  }, [props.defaultValue]);
  const getApiCallName = () => {
    setapiCallDetails({
      apiCallName: props?.apiCall,
      facilityId: props?.facilityIdForSearch,
      sysytemFieldName: props.sysytemFieldName,
    });
    setSearchedValue(props?.value);
  };

  useEffect(() => {
    if (
      props?.errorFocussedInput === inputElementReactSelect.current ||
      props?.errorFocussedInput === inputElementReactSelect.current.id
    ) {
      inputElementReactSelect.current.focus();
    }
  }, [props?.errorFocussedInput]);
  const setPatienntFirstNameLastName = () => {
    if (props.defaultValue) {
      setSearchedValue(props.defaultValue);
    }
  };
  const location = useLocation();
  return (
    <>
      <OutsiderAlert
        setSuggestions={setSuggestions}
        setSearchedValue={setSearchedValue}
        setSelectedSuggestion={setSelectedSuggestion}
        setActiveSuggestion={setActiveSuggestion}
        setTouched={setTouched}
      >
        <div
          id={props?.sysytemFieldName}
          ref={inputElementReactSelect}
          tabIndex={-1}
        ></div>
        <div className={`${props.parentDivClassName} position-relative`}>
          <div className="position-relative">
            <label
              className={
                props?.required ? "required mb-2 fw-500" : "mb-2 fw-500"
              }
              //htmlFor={props.id}
            >
              {t(props.label)}
            </label>
            <input
              disabled={props.disabled}
              className={`${props.parentDivClassName} form-control bg-transparent mb-2`}
              placeholder={props?.placeholder}
              value={
                typeof searchedValue === "string"
                  ? searchedValue
                  : typeof searchedValue === "object"
                  ? searchedValue?.code
                  : ""
              }
              onChange={async (event: {
                target: { value: SetStateAction<string> };
              }) => {
                handleChange(event);
                let newInputs = await assignFormValues(
                  props.Inputs,
                  props?.dependenceyControls,
                  props?.index,
                  props?.depControlIndex,
                  props?.fieldIndex,
                  event.target.value,
                  props?.isDependency,
                  props?.repeatFieldSection,
                  props?.isDependencyRepeatFields,
                  props?.repeatFieldIndex,
                  props?.repeatDependencySectionIndex,
                  props?.repeatDepFieldIndex,
                  undefined,
                  props?.setInputs
                );
                if (!location?.state?.reqId) {
                  props?.setInputs(newInputs);
                }
              }}
              onKeyDown={handleKeyDown}
              ref={inputSearchRef}
              color="secondary"
            />
            {props.error && (
              <div className="form__error">
                <span>{t(props.error)}</span>
              </div>
            )}

            {loading ? (
              <div
                style={{ top: "55%", zIndex: "9" }}
                className="position-absolute end-0 mr-2"
              >
                <Splash />
              </div>
            ) : null}
          </div>

          {suggestions?.length ? (
            <div
              className={`bg-white card position-absolute px-3 py-2 shadow-xs w-100 position-relative  ${
                !touched ? "d-none" : ""
              }`}
              style={{
                zIndex: "6",
                overflowY: "auto",
                maxHeight: "400px",
                height: "fit-content",
              }}
            >
              <div>
                {!suggestions.length &&
                searchedValue.length &&
                !selectedSuggestion.length ? (
                  <div className="row">
                    <div>
                      <div>{t("Nothing to show :")}</div>
                    </div>
                  </div>
                ) : (
                  <>
                    {Array.isArray(suggestions) &&
                      suggestions?.map((item: any, index) => (
                        <div
                          key={index}
                          style={{ cursor: "pointer" }}
                          className="p-1"
                          // className={`${classes.itemList} ${
                          //   index === activeSuggestion - 1 ? classes.activeItem : ""
                          // }`}
                          onClick={() => {
                            handleClick(item);
                            props?.apiCall === "Patient"
                              ? props?.setValues(
                                  item,
                                  props.Inputs,
                                  props.setInputs,
                                  props?.loadReqSec()
                                )
                              : props?.setValues((preVal: any) => {
                                  return {
                                    ...preVal,
                                    icD10CodeDescription: item?.description,
                                    icD10Code: item?.code,
                                    icd10id: item?.icd10id,
                                  };
                                });
                          }}
                        >
                          <>
                            <div
                              key={index}
                              className="bg-hover-light-primary d-flex gap-2 flex-wrap py-2 px-4 rounded-4"
                              style={{
                                borderBottom: "1.5px solid var(--kt-primary)",
                              }}
                            >
                              {Object.keys(suggestions[index])?.map(
                                (key, i) => (
                                  <>
                                    {key !== "PatientId" &&
                                      key !== "Insurances" && (
                                        <div className="text-hover-primary d-flex">
                                          <span className="fw-600 fs-7">
                                            {key?.toLocaleUpperCase()} :
                                          </span>
                                          <span className="pl-2 fs-7">
                                            {" "}
                                            {typeof suggestions[index][key] ===
                                              "string" &&
                                              suggestions[index][key]}
                                            {/* {suggestions[index][key]} */}
                                          </span>
                                          <br />
                                        </div>
                                      )}
                                  </>
                                )
                              )}
                            </div>
                            {/* <div className="bg-primary w-100 my-2" style={{height: '1px',border: '1px solid var(--kt-primary)'}}></div> */}
                          </>
                          <div>
                            <span>
                              {item?.icd10id}: {item?.code}:{item?.description}
                            </span>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
          ) : null}

          {/* <span>ICD10 Code Selected: {selectedSuggestion}</span> */}
        </div>
      </OutsiderAlert>
    </>
  );
};

export default Autocomplete;
