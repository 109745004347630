import { t } from "i18next";
import { isJson } from "Utils/Common/Requisition";
export const showFieldValue = (fieldsInfo: any) => {
  let key = fieldsInfo.displayFieldName;
  let value = fieldsInfo.defaultValue;
  let result = isJson(value);
  if (result) {
    value = JSON.parse(value);
  }
  let displayType = fieldsInfo.previewDisplayType;
  let systemFieldName = fieldsInfo.systemFieldName;
  console.log(value, "vall");
  switch (true) {
    case key && value && displayType:
      return (
        <div className={displayType}>
          {Array.isArray(value)
            ? value.map((items) => (
                <>
                  <span className="fw-bold">{items.label}</span>
                  <span>{items.value}</span>
                </>
              ))
            : value}
        </div>
      );
    case fieldsInfo.uiType === "File":
      return (
        <div className={`${displayType} d-flex justify-content-between`}>
          <span className="fw-bold">{key}</span>
          <div className="gap-2">
            {value.map((fileObj: any) => (
              <span className="badge badge-light-primary round-3">
                {fileObj.fileName}
              </span>
            ))}
          </div>
        </div>
      );

    default:
      return systemFieldName === "RepeatStart" ? (
        <div className="mb-2">
          <hr />
        </div>
      ) : (
        <div
          className={`${displayType} d-flex justify-content-between align-items-center`}
        >
          <div className={`fw-bold`}>{t(key)}</div>
          <div className="d-flex gap-2 flex-wrap">
            {Array.isArray(value)
              ? value?.map((items) => (
                  <>
                    <span className="badge badge-secondary round-3">
                      {items?.value}
                    </span>
                  </>
                ))
              : value}
          </div>
        </div>
      );
  }
};
