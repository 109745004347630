import { useEffect, useRef, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

const useIdleTimeout = ({ onIdle, onLogout, idleTime }: any) => {
  const idleTimeout = 1200000;
  const logoutTimeoutRef = useRef<any>(null);
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => {
    if (!sessionStorage.getItem("userinfo")) return;
    setIdle(true);
    onIdle();
    clearTimeout(logoutTimeoutRef.current);
    logoutTimeoutRef.current = setTimeout(() => {
      onLogout();
    }, idleTimeout);
  };

  const { reset } = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleIdle,
    debounce: 500,
    crossTab: true,
  });

  const resetAll = () => {
    reset();
    clearTimeout(logoutTimeoutRef.current);
    setIdle(false);
  };

  useEffect(() => {
    const resetIdleTimeoutAcrossTabs = () => {
      localStorage.setItem("resetTime", Date.now().toString());
    };

    const resetIdleTimeoutHandler = () => {
      reset();
      resetIdleTimeoutAcrossTabs();
    };

    window.addEventListener("mousemove", resetIdleTimeoutHandler);
    window.addEventListener("keypress", resetIdleTimeoutHandler);

    return () => {
      window.removeEventListener("mousemove", resetIdleTimeoutHandler);
      window.removeEventListener("keypress", resetIdleTimeoutHandler);
    };
  }, [reset]);

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === "continueClicked") {
        resetAll();
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return {
    isIdle,
    setIdle,
    reset: resetAll,
  };
};

export default useIdleTimeout;
