import RequisitionFields from "./SectionsUi/RequisitionFields";
const SectionCardBody = (props: any) => {
  return (
    <>
      <RequisitionFields fieldsInfo={props.fieldsInfo} />
    </>
  );
};

export default SectionCardBody;
