import { showFieldValue } from "./showFields";
const RequisitionFields = (props: any) => {
  console.log(props, "props.1");

  if (props?.fieldsInfo?.systemFieldName === "RepeatStart") {
    return (
      <>
        {Array.isArray(props?.fieldsInfo?.repeatFields) &&
          props.fieldsInfo.repeatFields.map((item: any, index: number) => (
            <div key={index}>{showFieldValue(item)}</div>
          ))}
      </>
    );
  } else {
    return <>{showFieldValue(props.fieldsInfo)}</>;
  }
};

export default RequisitionFields;
