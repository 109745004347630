import React, { useState, useEffect } from "react";
import { AxiosError, AxiosResponse } from "axios";
// import { Loader } from "../../../Shared/Common/Loader";
import { toast } from "react-toastify";
import UserManagementService from "../../../../Services/UserManagement/UserManagementService";
import { AddUser } from "./AddUser";
import {
  setAddUserFormState,
  setEditFormState,
} from "../../../Admin/InitialState";
import useForm from "../../../../Shared/hooks/useForm";
import validate from "../../../../Utils/validate";
import { Link, useNavigate, useParams } from "react-router-dom";
import Facilities from "./Facilities";
import Input from "../../../../Shared/Common/Input/Input";
import Select from "react-select";
import { EyeIcon, EyeIconSlash, RefreshIcon } from "../../../../Shared/Icons";
import {
  genderChoices,
  generateAutoGeneratePassword,
  styles,
} from "../../../../Utils/Common";
import Radio from "../../../../Shared/Common/Input/Radio";
import AdditionalUserRole from "../../../Admin/UserManagement/AdditionalUserRole";
import {
  GlobalAccountTypeEnum,
  PortalTypeEnum,
} from "../../../../Utils/Common/Enums/Enums";
import useLang from "Shared/hooks/useLanguage";
export interface IFormValues {
  facilitiesIds: [];
  [key: string]: string | boolean | null | number | Array<string>;
}
export interface IPage {
  claimsId: number;
  isChecked: boolean;
}
export interface IAdminType {
  value: number;
  label: string;
}
export interface IUserGroup {
  userGroupId: number;
  name: string;
}
export default function EditFacilityUser() {
  const { t } = useLang()
  const [isVisibility, setIsVisibility] = useState(false);
  const [formData2, setformData2] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeType, setActiveType] = useState("");
  const initialState = setAddUserFormState(activeType);
  const {
    formData,
    setFormData,
    setDataAndErrors,
    errors,
    changeHandler,
    setErrors,
  }: any = useForm(initialState, validate);
  const [sports2, setSports2] = useState<any>([]);
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    EditFacilityUser(id);
    loadFacilities();
    loadData();
    loadData1();
    // GetDataAgainstRoles(formData, formData.id.value)
  }, []);
  // useEffect(() => {
  //   const matchingFacilities = facilities.filter((factItem: any) =>
  //     temp.some((fact: any) => fact.id === factItem.value),
  //   )
  //   setSports2(matchingFacilities)
  // }, [temp, facilities])
  const [dropDownValues, setDropDownValues] = useState({
    UserGroupList: [],
    AdminTypeList: [],
  });
  const [dragSports, setDragSports] = useState<any>([]);
  const [dragSports2, setDragSports2] = useState<any>([]);

  const [values, setValues] = useState<IFormValues>({
    facilitiesIds: [],
  });

  const loadData = () => {
    UserManagementService.getUserType()
      .then((res: AxiosResponse) => {
        console.log("AdminType", res);
        let AdminTypeArray: any = [];
        res?.data?.forEach((val: IAdminType) => {
          let adminTypeDetails = {
            value: val?.value,
            label: val?.label,
          };
          AdminTypeArray.push(adminTypeDetails);
        });
        console.log("AdminTypeArray", AdminTypeArray);
        setDropDownValues((pre: any) => {
          return {
            ...pre,
            AdminTypeList: AdminTypeArray,
          };
        });
      })
      .catch((err: any) => {
        console.trace(err);
      });
  };
  const loadData1 = () => {
    UserManagementService.GetAllUserRoleList(PortalTypeEnum.Facility)
      .then((res: AxiosResponse) => {
        console.log("new user role type", res);
        setDropDownValues((pre: any) => {
          return {
            ...pre,
            UserGroupList: res?.data?.data,
          };
        });
      })
      .catch((err: any) => {
        console.trace(err);
      });
  };
  const [loading, setLoading] = useState(false);
  console.log(formData, "formData009");

  const loadFacilities = () => {
    setLoading(true);
    UserManagementService.GetFacilitiesLookup()
      .then((res: AxiosResponse) => {
        console.log("FacilityLookup", res.data);

        setFacilities(res?.data);
        // setSports2(res.data.data.facilities)
      })
      .catch((err: any) => {
        console.trace(err, "err");
      })
      .finally(() => setLoading(false));
  };
  const [loader, setLoader] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let formErrors: any;
    formErrors = validate(formData, true);
    setErrors(formErrors);
    let size = Object.keys(formErrors).length;

    console.log(size, "size");

    let objToSend = {
      id: formData.id.value,
      facilities: values.facilitiesIds,
      adminType: formData.adminType.value,
      firstName: formData.firstName.value,
      lastName: formData.lastName.value,
      npiNumber: formData.npiNumber.value,
      stateLicense: formData.stateLicense.value,
      accountType: parseInt(formData.accountType.value),
      username:
        formData?.accountType.value === 0 ? formData.userName.value : "",
      password:
        formData?.accountType.value === 0 ? formData.password.value : "",
      adminEmail:
        formData?.accountType.value === 1 ? formData.adminEmail.value : "",
      phoneNumber: formData.phoneNumber.value,
      gender: formData?.gender.value,
      modules: checkboxes,
    };
    const isAtLeastOneModuleSelected = objToSend.modules.some(
      (module: any) => module.isSelected
    );
    if (
      (size == 3 && formData?.adminType?.value.toString() === "72") ||
      (formData?.adminType?.value.toString() != "72" && size == 4)
    ) {
      if (values.facilitiesIds.length !== 0) {
        if (isAtLeastOneModuleSelected) {
          setLoader(true);
          UserManagementService.SaveOrEditFacilityUser(objToSend)
            .then((res: AxiosResponse) => {
              if (res.data.statusCode === 200) {
                toast.success(res.data.responseMessage);
                setTimeout(() => {
                  navigate("/facility-user-list");
                }, 1000);
              } else if (res.data.statusCode === 400) {
                toast.error(res.data.responseMessage);
              } else {
                console.log("Something went wrong in update or save facility");
              }
            })
            .catch((err: AxiosError) =>
              console.log(err, "err while creating user")
            )
            .finally(() => setLoader(false));
        } else {
          toast.error("Select at least one role");
        }
      } else {
        toast.error("Select atleast one facility");
      }
    }
  };
  const [enable, setEnable] = useState(false);
  const EditFacilityUser = async (id: any) => {
    await UserManagementService.getFacilityUserAgainstId(atob(id))
      .then((res: AxiosResponse) => {
        setActiveType(String(res?.data?.data.accountType));
        const updatedState = setEditFormState(initialState, res.data.data);
        setEnable(res.data.data.adminType == null ? false : true);
        setFormData(updatedState);
        setSports2(res.data.data.facilities);
        setCheckboxes(res?.data?.data.modules);
      })
      .catch((err: AxiosError) => console.log(err, "err while creating user"));
  };
  const [checkboxes, setCheckboxes] = useState([]);
  ////////////-----------------Get Data Against Roles-------------------///////////////////
  const GetDataAgainstRolesByUserId = async (userId: string) => {
    await UserManagementService?.getByIdAllUserRolesAndPermissions(userId)
      .then((res: AxiosResponse) => {
        console.log(userId);
        console.log(res);
        if (res?.data?.status === 200) {
          setCheckboxes(res?.data?.data.modules);
        } else if (res?.data?.status === 400) {
          toast.error(res?.data?.message);
        }
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };
  const GetDataAgainstRoles = async (RoleId: number) => {
    await UserManagementService?.GetDataAgainstRoles(RoleId)
      .then((res: AxiosResponse) => {
        if (res?.data?.statusCode === 200) {
          setCheckboxes(res?.data?.data);
        } else if (res?.data?.statusCode === 400) {
          toast.error(res?.data?.message);
        }
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };
  // const changeHandlerForNames = (e: any) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: {
  //       value: value,
  //     },
  //   });
  // };
  const changeHandlerForNpi = (e: any) => {
    // Get the input value
    const inputValue = e.target.value;

    // Check if it's a number and limit it to 10 digits
    const numericValue = inputValue.replace(/\D/g, "").slice(0, 10);

    // Update the formData state with the limited value
    setFormData({
      ...formData,
      npiNumber: {
        ...formData.npiNumber,
        value: numericValue,
      },
    });
  };
  const changeHandlerForEmail = (e: any) => {
    // Get the input value and convert it to lowercase
    const inputValue = e.target.value.toLowerCase();

    // Update the formData state with the lowercase value
    setFormData({
      ...formData,
      adminEmail: {
        ...formData.adminEmail,
        value: inputValue,
      },
    });
  };
  return (
    <>
      <div className="app-toolbar py-3 py-lg-6">
        <div className="app-container container-fluid d-flex flex-wrap gap-4 justify-content-center justify-content-sm-between align-items-center">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <ul className="breadcrumb breadcrumb-separatorless  fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="" className="text-muted text-hover-primary">
                {t("Home")}
                </a>
              </li>

              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px"></span>
              </li>

              <li className="breadcrumb-item text-muted">{t("Admin")}</li>

              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px"></span>
              </li>

              <li className="breadcrumb-item text-muted">
              {t("Edit a Facility User")}
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <Link
              type="button"
              className="btn btn-secondary btn-sm btn-secondary--icon"
              id="kt_reset"
              to={"/facility-user-list"}
            >
              <span>
                <span>{t("cancel")}</span>
              </span>
            </Link>
            <button
              className="btn btn-primary btn-sm btn-primary--icon px-7"
              onClick={handleSubmit}
            >
              <span>
                {loader ? (
                  <span>{t("Updating...")}</span>
                ) : (
                  <>
                    <span>{t("Update")}</span>
                  </>
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="app-container container-fluid">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="card shadow-sm mb-3 rounded">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="m-0 ">{t("Edit Facility User")}</h5>
            </div>
            <div className="card-body py-md-4 py-3">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  {/* <label className="mb-2 required">User Role</label> */}
                  <label className="mb-2 required">{t("User Type")}</label>
                  <Select
                    menuPortalTarget={document.body}
                    theme={(theme: any) => styles(theme)}
                    options={dropDownValues.UserGroupList}
                    onChange={(event: any) => {
                      let updatedData = {
                        ...formData,
                        adminType: {
                          ...formData["adminType"],
                          value: event.value,
                          touched: true,
                        },
                      };

                      setDataAndErrors(updatedData);
                      if (enable) {
                        GetDataAgainstRolesByUserId(formData.id.value);
                      } else {
                        GetDataAgainstRoles(event.value);
                      }
                    }}
                    isDisabled={enable}
                    value={dropDownValues?.UserGroupList.filter(function (
                      option: any
                    ) {
                      return option.value === String(formData.adminType.value);
                    })}
                  />
                  <div className="form__error">
                    <span>{errors?.adminType}</span>
                  </div>
                </div>
                <Input
                  type="text"
                  label="First Name"
                  name="firstName"
                  onChange={changeHandler}
                  parentDivClassName="col-lg-3 col-md-6 col-sm-12"
                  placeholder="First Name"
                  value={formData?.firstName?.value}
                  error={errors?.firstName}
                  //loading={loading}
                  required={true}
                />
                <Input
                  type="text"
                  label="Last Name"
                  name="lastName"
                  onChange={changeHandler}
                  parentDivClassName="col-lg-3 col-md-6 col-sm-12"
                  placeholder="Last Name"
                  value={formData?.lastName?.value}
                  error={errors?.lastName}
                  //loading={loading}
                  required={true}
                />
                {formData?.adminType?.value === 72 ? (
                  <>
                    <Input
                      type="text"
                      label="NPI #"
                      name="npiNumber"
                      onChange={changeHandlerForNpi}
                      parentDivClassName="col-lg-3 col-md-6 col-sm-12"
                      placeholder="NPI #"
                      value={formData?.npiNumber?.value}
                      error={errors?.npiNumber}
                      //loading={loading}
                      required={true}
                    />
                    <Input
                      type="text"
                      label="State License #"
                      name="stateLicense"
                      //onBlur={onKeyUp}
                      onChange={changeHandler}
                      parentDivClassName="col-lg-3 col-md-6 col-sm-12"
                      placeholder="state License #"
                      value={formData?.stateLicense?.value}
                      error={errors?.stateLicense}
                      //loading={loading}
                      required={false}
                    />
                  </>
                ) : null}

                {/* {AddFacilityUserInputs?.map((input: IInput, index: number) => (
              <div className="col-lg-3 col-md-6 col-sm-12">
              <FormInput
                  key={index}
                  {...input}
                  value={values[input.name]}
                  onChange={handleOnChange}
              />
              </div>
              ))} */}
                <div className="mb-5 col-lg-3 col-md-6 col-sm-12">
                  <label className="required mb-2 fw-500">{t("Gender")}</label>
                  <div className="row m-0">
                    {genderChoices.map((choice: any) => (
                      <>
                        <label className="form-check form-check-sm form-check-solid col-6 my-1">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id={choice.id}
                            onChange={changeHandler}
                            checked={formData?.gender.value === choice.value}
                            value={choice.value}
                          />
                          <span className="form-check-label">
                            {choice.label}
                          </span>
                        </label>
                      </>
                    ))}
                    {errors.gender && (
                      <div className="form__error">
                        <span>{errors.gender}</span>
                      </div>
                    )}
                  </div>
                </div>
                <Input
                  type="tel"
                  label="Phone No"
                  name="phoneNumber"
                  onChange={changeHandler}
                  parentDivClassName="col-lg-3 col-md-6 col-sm-12"
                  placeholder="Phone No"
                  value={formData?.phoneNumber?.value}
                  error={errors?.phoneNumber}
                  //loading={loading}
                  required={true}
                />
                <div className="col-lg-3 col-md-6 col-sm-12">
                  {/* <!--begin::Input group--> */}
                  <div className="fv-row mb-4">
                    <Radio
                      label="Account Type"
                      name="accountType"
                      onChange={changeHandler}
                      choices={[
                        {
                          id: "Username1",
                          label: "Username",
                          value: String(GlobalAccountTypeEnum.Username),
                        },
                        {
                          id: "Email1",
                          label: "Email",
                          value: String(GlobalAccountTypeEnum.Email),
                        },
                      ]}
                      error={errors.accountType}
                      checked={formData.accountType.value.toString()}
                      // loading={loading}
                      disabled={true}
                    />
                  </div>
                </div>
                {formData.accountType.value === 0 ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12 thenuser mb-4">
                      {/* <!--begin::Input group--> */}

                      <label className="required mb-2 fw-500">{t("UserName")}</label>
                      <input
                        type="text"
                        name="userName"
                        onChange={changeHandler}
                        className={
                          formData.id.value
                            ? "form-control bg-secondary"
                            : "form-control bg-transparent"
                        }
                        placeholder="UserName"
                        value={formData?.userName?.value}
                        autoComplete="off"
                        disabled={true}
                      />
                      {errors?.userName && (
                        <div className="form__error">
                          <span>{errors?.userName}</span>
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
                {formData.accountType.value == "1" ? (
                  <div className="col-lg-3 col-md-6 col-sm-12 thenemail mb-4">
                    {/* <!--begin::Input group--> */}
                    {/* <!--begin::Label--> */}
                    <label className="required mb-2 fw-500">{t("Email")}</label>
                    {/* <!--end::Label--> */}
                    {/* <!--begin::Input--> */}
                    <input
                      type="text"
                      name="adminEmail"
                      //onBlur={ValidEmail}
                      onChange={changeHandlerForEmail}
                      value={formData?.adminEmail?.value}
                      className={
                        formData.id.value
                          ? "form-control bg-secondary"
                          : "form-control bg-transparent"
                      }
                      placeholder="Email"
                      disabled={true}
                    />

                    {errors?.adminEmail && (
                      <div className="form__error">
                        <span> {errors?.adminEmail}</span>
                      </div>
                    )}
                    {/* <!--end::Input--> */}
                    {/* <!--end::Input group--> */}
                  </div>
                ) : null}
              </div>

              <AdditionalUserRole
                checkboxes={checkboxes}
                setCheckboxes={setCheckboxes}
              />
              {/* Edit */}
              <Facilities
                setDragSports={setDragSports}
                setDragSports2={setDragSports2}
                facilities={facilities}
                values={values}
                setValues={setValues}
                sports2={sports2}
                setSports2={setSports2}
              />
            </div>
          </div>
        </div>
      </div>
    </>
    // <>
    //   <AddUser
    //     handleOnChange={handleOnChange}
    //     setDataAndErrors={setDataAndErrors}
    //     errors={errors}
    //     changeHandler={changeHandler}
    //     values={values}
    //     formData={formData}
    //     setValues={setValues}
    //     handleSubmit={handleSubmit}
    //     setDragSports={setDragSports}
    //     facilities={facilities}
    //     setActiveType={setActiveType}
    //   />
    // </>
  );
}
