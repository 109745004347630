import { Paper, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PatientServices from "Services/PatientServices/PatientServices";
import { Loader } from "Shared/Common/Loader";
import NoRecord from "Shared/Common/NoRecord";
import { AutocompleteStyle } from "Utils/MuiStyles/AutocompleteStyles";
import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import ColumnSetup from "../ColumnSetup/ColumnSetup";
import useLang from './../../../Shared/hooks/useLanguage';
import Row from "./Row";

export interface TabConfiguration {
  tabName: string;
  tabID: number;
}

const RightPanel = ({
  paneNumber,
  bulkIds,
  tabData,
  tabPanels,
  value,
  setValue,
  tabIdToSend,
  setTabIdToSend,
  setBulkActions,
  setColumnsActions,
  setTopButtonActions,
  setBulkExportActions,
  setBulkIds,
  bulkActions,
  bulkExportActions,
  topButtonActions,
  columnDataActions,
  columnActions,
  rows,
  setRows,
  setRows2,
  // total,
  dynamicGridSplitPane,
  getTabsForSplitPane,
  loading,
  setSearchValue,
  setLoading,
  inputFields,
  setInputFields,
  bottomButtonsPane1,
}: any) => {
  const {t} = useLang()
  // const {
  //   curPage,
  //   pageSize,
  //   totalPages,
  //   pageNumbers,
  //   nextPage,
  //   prevPage,
  //   showPage,
  //   setPageSize,
  //   setTotal,
  //   total,
  //   setCurPage,
  // } = table2Pagination;

  // useEffect(() => {
  //   setCurPage(1);
  //   // setTotal(total);
  // }, []);

  console.log(inputFields, "inputFields");

  const [openCollapse, setOpenCollapse] = useState(false);
  const [spareTabId, setSpareTabId] = useState(1);
  const [apiData, setApiData] = useState<any>({});
  // const [inputFields, setInputFields] = useState([]);

  const isFirstLoad = useRef(true);
  const [triggerSearchData, setTriggerSearchData] = useState(false);
  const ModalhandleClose1 = () => setShow1(false);
  const [show1, setShow1] = useState(false);
  const [showSetupModal, showModalSetup] = useState(false);
  const [isBulkEdit, setIsBulkEdit] = useState(false);

  const searchRef = useRef<any>(null);

  const initalSortingObj = {
    clickedIconData: "",
    sortingOrder: "",
  };

  const [sort, setSorting] = useState(initalSortingObj);
  // const [loading, setLoading] = useState(true);
  const [tabsLoading, setTabsLoading] = useState(true);
  const [clicked, setClicked] = useState(true);
  const [filters, setFilters] = useState<any>([]);

  // const initialQueryObj = {
  //   tabId: 1,
  //   pageNumber: 1,
  //   pageSize: 25,
  //   sortColumn: sort.clickedIconData,
  //   sortDirection: sort.sortingOrder,
  //   filters: [],
  // };

  // const [searchValue, setSearchValue] =
  //   useState<Record<string, any>>(initialQueryObj);
  const [expandableColumnsHeader, setExpandableColumnsHeader] = useState<any>(
    []
  );

  let columnsHeader =
    paneNumber?.viewReqTabsWithHeadersResponse?.[value]?.tabHeaders;

  const isExpandable = () =>
    columnsHeader?.some((column: any) => column.isExpandData);

  const DeletebyId = async (item: any) => {
    try {
      const response = await PatientServices?.deletebyid(item);
      toast.success(response.data.message);
      // loadData(true);
    } catch (error) {
      console.error(error);
    }
  };

  // const loadData = async (reset: boolean) => {
  //   if (tabIdToSend !== spareTabId) return;
  //   setLoading(true);
  //   RequisitionType.dynamicGrid(
  //     !reset
  //       ? {
  //           ...searchValue,
  //           tabId: tabIdToSend,
  //           sortColumn: sort.clickedIconData,
  //           sortDirection: sort.sortingOrder,
  //           pageNumber: curPage,
  //           pageSize: pageSize,
  //         }
  //       : {
  //           ...initialQueryObj,
  //           tabId: tabIdToSend,
  //           sortColumn: initalSortingObj.clickedIconData,
  //           sortDirection: initalSortingObj.sortingOrder,
  //           pageNumber: curPage,
  //           pageSize,
  //         }
  //   )
  //     .then((res: AxiosResponse) => {
  //       setRows(res.data.data);
  //       setTotal(res?.data?.total);
  //     })
  //     .catch((err: AxiosError) => console.trace(err))
  //     .finally(() => setLoading(false));
  // };

  // const resetSearch = async () => {
  //   setFilters([]);
  //   setClicked(!clicked);
  //   setSorting(initalSortingObj);
  //   setSearchValue((prev) => ({
  //     ...prev,
  //     ...initialQueryObj,
  //     tabId: value,
  //   }));
  // };

  const delayedCall = async () => {
    dynamicGridSplitPane();
  };

  const closeSetupModal = () => {
    showModalSetup(false);
  };

  const handleSort = (columnName: any) => {
    searchRef.current.id = searchRef.current.id
      ? searchRef.current.id === "asc"
        ? (searchRef.current.id = "desc")
        : (searchRef.current.id = "asc")
      : (searchRef.current.id = "asc");

    setSorting({
      sortingOrder: searchRef?.current?.id,
      clickedIconData: columnName,
    });
  };

  useEffect(() => {
    if (columnsHeader?.length) {
      setExpandableColumnsHeader(
        columnsHeader
          .map((item: any) => {
            if (item.isShowOnUi && item.isExpandData) {
              return item;
            } else return null;
          })
          .filter((item: any) => item !== null)
      );
    }
  }, [columnsHeader]);

  // /**
  //  * Loads tab data and configurations for the dynamic grid.
  //  *
  //  * This asynchronous function fetches data from an API, processes it, and updates various state variables
  //  * with the retrieved tab information and configurations. It handles the following tasks:
  //  *
  //  * 1. Fetches tab data using `RequisitionType.getViewReqGridData()`.
  //  * 2. Extracts and processes input fields based on visibility and configuration.
  //  * 3. Filters and structures the tab data.
  //  * 4. Updates state variables related to column actions, bulk actions, and other configurations.
  //  * 5. Initializes the selected tab on the first load.
  //  *
  //  * This function includes error handling and ensures that loading states are properly managed.
  //  *
  //  * @async
  //  * @function
  //  * @returns {Promise<void>} Resolves when the data has been successfully loaded and state updated.
  //  */
  // const loadTabsForDynamicGrid = async () => {
  //   try {
  //     const response = await MiscellaneousService.getTabsForSplitPane();
  //     const data =
  //       response?.data?.data?.viewReqTabsWithHeadersResponse?.[value];

  //     const inputFields = data?.tabHeaders?.map((column: any) => {
  //       if (column.isShowOnUi && !column.isExpandData && column.isShow) {
  //         return {
  //           inputType: column.filterColumnsType,
  //           name: column.columnKey,
  //           jsonOptionData: column.jsonOptionData,
  //           fieldName: column.fieldName,
  //           isIndividualEditable: column.isIndividualEditable,
  //         };
  //       }
  //     });
  //     let filteredTabData = response?.data?.data?.map((tab: any) => ({
  //       tabName: tab.tabName,
  //       tabID: tab.tabID,
  //       sortOrder: tab.sortOrder,
  //     }));

  //     setColumnsActions(response?.data?.data[0]?.tabActions);
  //     setColumnDataActions(response?.data?.data[0]?.columnAction);
  //     setBulkActions(response?.data?.data[0]?.bulkActions);
  //     setBulkExportActions(response?.data?.data[0]?.bulkExportActions);
  //     setTopButtonActions(response?.data?.data[0]?.topButtonAction);

  //     setInputFields(inputFields);
  //     setTabPanels(filteredTabData);
  //     if (isFirstLoad.current) {
  //       setValue(filteredTabData[0]?.sortOrder - 1);
  //       isFirstLoad.current = false;
  //     }

  //     setTabIdToSend(filteredTabData[0]?.tabID);
  //     setTabData((preVal: any) => {
  //       return {
  //         ...preVal,
  //         gridHeaders: response?.data?.data,
  //       };
  //     });
  //   } catch (error) {
  //     return error;
  //   } finally {
  //     setTabsLoading(false);
  //   }
  // };

  const fetchDataSequentially = async (reset: boolean = true) => {
    try {
      // await loadTabsForDynamicGrid();
      // await loadData(reset);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataSequentially();
  }, []);

  /**
   * Handles the tab change event and updates the state accordingly.
   *
   * This asynchronous function is triggered when a tab is changed.
   * It performs the following actions:
   *
   * 1. Extracts the ID from the event target and determines the tab number.
   * 2. Retrieves the tab data based on the `sortOrder`.
   * 3. Maps over the tab headers to extract input field information and sets `inputFields`.
   * 4. Updates various state variables including `value`, `tabIdToSend`, `spareTabId`, `bulkActions`, `columnsActions`, `topButtonActions`, and `bulkExportActions`.
   *
   * @param {React.SyntheticEvent} event - The event object from the tab change action.
   * @param {number} newValue - The new tab index or value.
   */
  // const handleTabChange = async (
  //   event: React.SyntheticEvent,
  //   newValue: number
  // ) => {
  //   let Id: any = event.currentTarget.id;
  //   let thenum: any = Id.match(/\d+/)[0];
  //   if (searchValue?.tabId !== thenum) {
  //     setSearchValue(() => {
  //       return {
  //         ...initialQueryObj,
  //         tabId: +thenum,
  //       };
  //     });
  //     setFilters(() => []);
  //   }

  //   const _tabId = +(event.target as any).id;

  //   const ariaControls: string | null =
  //     event.currentTarget.getAttribute("aria-controls");

  //   let sortOrder: any = null;
  //   if (ariaControls) {
  //     const parts = ariaControls.split("-");
  //     sortOrder = +parts[parts.length - 1];
  //   }

  //   let _tabData: any = null;
  //   if (sortOrder === 0) {
  //     _tabData = tabData?.gridHeaders?.[sortOrder];
  //   } else {
  //     _tabData = tabData?.gridHeaders?.[sortOrder - 1];
  //   }

  //   const inputFields = _tabData?.tabHeaders?.map((column: any) => {
  //     if (column.isShowOnUi && !column.isExpandData && column.isShow) {
  //       return {
  //         inputType: column.filterColumnsType,
  //         name: column.columnKey,
  //         jsonOptionData: column.jsonOptionData,
  //         fieldName: column.fieldName,
  //         isIndividualEditable: column.isIndividualEditable,
  //       };
  //     }
  //   });
  //   setInputFields(inputFields);

  //   setValue(newValue);
  //   setTabIdToSend(_tabId);
  //   setSpareTabId(_tabId);
  //   setBulkActions(_tabData?.bulkActions);
  //   setColumnsActions(_tabData?.tabActions);
  //   setTopButtonActions(_tabData?.topButtonAction);
  //   setBulkExportActions(_tabData?.bulkExportActions);
  // };

  useEffect(() => {
    setTabIdToSend(spareTabId);
  }, [tabIdToSend]);

  const [isInitialRender, setIsInitialRender] = useState(false);

  useEffect(() => {
    if (isInitialRender) {
      dynamicGridSplitPane();
    } else {
      setIsInitialRender(true);
    }
  }, [sort, triggerSearchData, value]);

  // console.log(pageSize, "pageSize");

  const [searchedTags, setSearchedTags] = useState<string[]>([]);

  useEffect(() => {
    let filteredObject: any = {};

    filters?.forEach((filterData: any) => {
      filteredObject[filterData.label] = filterData.filterValue;
    });

    const uniqueKeys = new Set<string>();
    for (const [key, value] of Object.entries(filteredObject)) {
      if (value) {
        uniqueKeys.add(key);
      }
    }
    setSearchedTags(Array.from(uniqueKeys));
  }, [filters]);

  // const handleTagRemoval = (clickedTag: string) => {
  //   let resultedTab = filters.filter((tab: any) => {
  //     return tab.label !== clickedTag;
  //   });

  //   setFilters(() => [...resultedTab]);
  //   setSearchValue((prevValue) => ({
  //     ...prevValue,
  //     filters: resultedTab,
  //   }));
  // };

  // useEffect(() => {
  //   const hasTags = searchedTags.length > 0;
  //   setOpenCollapse(hasTags);

  //   if (!hasTags) {
  //     resetSearch();
  //   }
  // }, [searchedTags.length]);

  /**
   * The `handleSelectAll` function allows you to select all rows for bulk actions.
   *
   * description:
   * This function collects the IDs of all rows and updates the `bulkIds` state variable
   */
  const handleSelectAll = (checked: boolean) => {
    if (!checked) {
      setBulkIds([]);
    } else {
      setBulkIds(rows.map((row: any) => row.Id));
    }
  };

  /**
   * `handleBulkUpdate` is asynchronous function used to handle bulk Edit
   *
   */
  const handleBulkUpdate = async () => {
    const nameToFieldMap = inputFields.reduce((map: any, item: any) => {
      if (item && item.fieldName !== undefined && !item.show) {
        map[item.name] = item.fieldName;
      }
      return map;
    }, {});

    let updatedRows = rows
      .map((item: any) => {
        const updatedData = Object.keys(item).reduce(
          (result: any, key: any) => {
            const fieldName = nameToFieldMap[key];

            if (fieldName) {
              result[fieldName] = item[key];
            }
            return result;
          },
          {}
        );

        if (item.rowStatus) {
          updatedData.rowStatus = item.rowStatus;
        }

        // appending Id in object
        if (Object.keys(updatedData).length > 0) {
          updatedData.Id = item.Id;
        }

        return updatedData;
      })
      .filter((item: any) => item.rowStatus === true) // filtering out objects with rowStatus as they are not editable
      .map((item: any) => {
        delete item.rowStatus;
        return item;
      });

    const payload = {
      tableId: 0,
      tabId: tabIdToSend,
      jsonFields: JSON.stringify(updatedRows),
    };
    await PatientServices.makeApiCallForDynamicGrid(
      apiData.url,
      apiData.method ?? null,
      payload
    );
    // loadData(false);
    setIsBulkEdit(false);
  };

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <ColumnSetup
        value={tabIdToSend}
        show={showSetupModal}
        paneNumber={paneNumber.paneNo}
        columnsToUse={columnsHeader}
        closeSetupModal={closeSetupModal}
        loadData={getTabsForSplitPane}
      />
      <div id="kt_app_toolbar" className="app-toolbar py-2 py-lg-3">
        <div className="app-container container-fluid d-flex flex-wrap gap-4 justify-content-end align-items-center">
          <div className="d-flex flex-wrap align-items-center gap-2 gap-lg-3">
            <button
              className="btn btn-icon btn-sm fw-bold btn-setting btn-icon-light"
              onClick={() => showModalSetup(true)}
            >
              <i className="fa fa-gear"></i>
            </button>
          </div>
        </div>
      </div>

      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className="mb-5 hover-scroll-x">
            {/* <Tabs
              value={value}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { background: "transparent", cursor: "pointer" },
              }}
              sx={{ p: 0 }}
              className="min-h-auto"
            >
              {Array.isArray(tabPanels) &&
                tabPanels?.map((items: any) => (
                  <TabSelected
                    key={items.tabID}
                    label={items.tabName}
                    {...a11yProps(items.tabID, items.sortOrder)}
                    className="fw-bold text-capitalize"
                    disabled={loading}
                  />
                ))}
            </Tabs> */}
            <div className="tab-pane" id="activetab" role="tabpanel">
              <div className="card tab-content-card">
                <div className="card-toolbar">
                  <div className="p-0 del-before"></div>
                </div>
                <div className="card-body py-md-4 py-3">
                  {/* <Collapse in={openCollapse}>
                    <div
                      className="d-flex gap-2 flex-wrap pb-2"
                      style={{ height: "40px" }}
                    >
                      {searchedTags.map((tag) => (
                        <div
                          className="d-flex align-items-center cursor-pointer gap-1 p-2 rounded bg-light"
                          onClick={() => handleTagRemoval(tag)}
                        >
                          <span className="fw-bold">{tag}</span>
                          <i className="bi bi-x"></i>
                        </div>
                      ))}
                    </div>
                  </Collapse> */}
                  {/* <div className="d-flex flex-wrap gap-2 align-items-center mb-2 justify-content-center justify-content-sm-between">
                    <div className="d-flex align-items-center">
                      <span className="fw-400 mr-3">Records</span>
                      <select
                        className="form-select w-125px h-33px rounded mr-3"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-dropdown-parent="#kt_menu_63b2e70320b73"
                        data-allow-clear="true"
                        onChange={(e) => {
                          // setBulkIds([]);
                          // setPageSize(parseInt(e.target.value));
                        }}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="50" selected>
                          50
                        </option>
                        <option value="100">100</option>
                      </select>
                      <div className="d-flex gap-2 flex-wrap">
                        {bulkActions?.length > 0 && (
                          <BulkActions
                            bulkIds={bulkIds}
                            setBulkIds={setBulkIds}
                            loadData={""}
                            setRows={setRows}
                            rows={rows}
                            setApiData={setApiData}
                            bulkActions={bulkActions}
                            inputFields={inputFields}
                            setIsBulkEdit={setIsBulkEdit}
                            setInputFields={setInputFields}
                          />
                        )}
                        {bulkExportActions?.length > 0 && (
                          <BulkExportActions
                            bulkIds={bulkIds}
                            setBulkIds={setBulkIds}
                            loadData={""}
                            bulkActions={bulkExportActions}
                            tabId={tabIdToSend}
                          />
                        )}
                        {topButtonActions?.length > 0 && (
                          <TopButtonActions
                            rows={rows}
                            tabID={tabIdToSend}
                            setRows={setRows}
                            loadData={""}
                            setApiData={setApiData}
                            inputFields={inputFields}
                            buttons={topButtonActions}
                          />
                        )}
                        {isBulkEdit && (
                          <div className="gap-1 d-flex">
                            <button
                              onClick={() => handleBulkUpdate()}
                              className="btn btn-icon btn-sm fw-bold btn-table-save btn-icon-light h-32px w-32px fas-icon-20px"
                            >
                              <DoneIcon />
                            </button>
                            <button
                              onClick={() => {
                                delayedCall();
                                setIsBulkEdit(false);
                              }}
                              className="btn btn-icon btn-sm fw-bold btn-table-cancel btn-icon-light h-32px w-32px fas-icon-20px"
                            >
                              <CrossIcon />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                      <button
                        onClick={() => {
                          // setCurPage(1);
                          setTriggerSearchData((prev) => !prev);
                        }}
                        className="btn btn-info btn-sm fw-500"
                        aria-controls="Search"
                      >
                        Search
                      </button>
                      <button
                        onClick={resetSearch}
                        type="button"
                        className="btn btn-secondary btn-sm btn-secondary--icon fw-500"
                        id="kt_reset"
                      >
                        <span>
                          <span>Reset</span>
                        </span>
                      </button>
                    </div>
                  </div> */}
                  <Box sx={{ height: "auto", width: "100%" }}>
                    <div className="table_bordered overflow-hidden">
                      <TableContainer
                        sx={{
                          maxHeight: "calc(100vh - 100px)",
                          "&::-webkit-scrollbar": {
                            width: 7,
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#fff",
                          },
                          "&:hover": {
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "var(--kt-gray-400)",
                              borderRadius: 2,
                            },
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "var(--kt-gray-400)",
                            borderRadius: 2,
                          },
                        }}
                        component={Paper}
                        className="shadow-none"
                      >
                        <Table
                          aria-label="sticky table collapsible"
                          className="table table-cutome-expend table-bordered table-sticky-header table-head-2-bg table-bg table-head-custom table-vertical-center border-0 mb-1"
                        >
                          <>
                            {!columnsHeader?.length ? null : tabsLoading &&
                              !columnsHeader?.length ? null : (
                              <TableHead className="h-40px">
                                {/* <TableRow>
                                  {bulkActions?.length ? (
                                    <TableCell></TableCell>
                                  ) : null}
                                  {isExpandable() ? (
                                    <TableCell></TableCell>
                                  ) : null}
                                  {!columnActions?.length ? null : (
                                    <TableCell></TableCell>
                                  )}
                                  {columnsHeader?.map(
                                    (column: any) =>
                                      column.isShowOnUi &&
                                      !column.isExpandData &&
                                      column.isShow && (
                                        <TableCell>
                                          {column?.filterColumns && (
                                            <PatientSearchInput
                                              loadData={""}
                                              column={column}
                                              searchValue={searchValue}
                                              filters={filters}
                                              setFilters={setFilters}
                                              setSearchValue={setSearchValue}
                                            />
                                          )}
                                        </TableCell>
                                      )
                                  )}
                                </TableRow> */}
                                <TableRow className="h-30px">
                                  {bulkActions?.length ? (
                                    <TableCell>
                                      <label className="form-check form-check-sm form-check-solid">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          checked={
                                            bulkIds.length === rows.length
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleSelectAll(e.target.checked)
                                          }
                                        />
                                      </label>
                                    </TableCell>
                                  ) : null}
                                  {isExpandable() ? (
                                    <TableCell></TableCell>
                                  ) : null}
                                  {!columnActions?.length ? null : (
                                    <TableCell>{t("Actions")}</TableCell>
                                  )}
                                  {columnsHeader?.map((column: any) => {
                                    return (
                                      column.isShowOnUi &&
                                      !column.isExpandData &&
                                      column.isShow && (
                                        <TableCell
                                          sx={{ width: "max-content" }}
                                        >
                                          <div
                                            className={`d-flex justify-content-between`}
                                            ref={searchRef}
                                            // onClick={() =>
                                            //   column.filterColumns
                                            //     ? handleSort(
                                            //         column.filterColumns
                                            //       )
                                            //     : null
                                            // }
                                          >
                                            <span style={{ minWidth: "100px" }}>
                                              {column.columnLabel}
                                            </span>
                                            {/* {column.filterColumns ? (
                                              <div className="d-flex justify-content-center align-items-center mx-4 mr-0">
                                                <ArrowUp
                                                  CustomeClass={`${
                                                    sort.sortingOrder ===
                                                      "asc" &&
                                                    sort.clickedIconData ===
                                                      column.filterColumns
                                                      ? "text-danger fs-6"
                                                      : "text-gray-700 fs-7"
                                                  }  p-0 m-0 "`}
                                                />
                                                <ArrowDown
                                                  CustomeClass={`${
                                                    sort.sortingOrder ===
                                                      "desc" &&
                                                    sort.clickedIconData ===
                                                      column.filterColumns
                                                      ? "text-danger fs-6"
                                                      : "text-gray-700 fs-7"
                                                  }  p-0 m-0`}
                                                />
                                              </div>
                                            ) : null} */}
                                          </div>
                                        </TableCell>
                                      )
                                    );
                                  })}
                                </TableRow>
                              </TableHead>
                            )}
                            <TableBody>
                              {loading ? (
                                <TableCell colSpan={9}>
                                  <Loader />
                                </TableCell>
                              ) : !rows.length ? (
                                <NoRecord />
                              ) : (
                                rows?.map((item: any) => (
                                  <Row
                                    rows={rows}
                                    item={item}
                                    setRows={setRows}
                                    bulkIds={bulkIds}
                                    apiData={apiData}
                                    loadData={""}
                                    isBulkEdit={isBulkEdit}
                                    setApiData={setApiData}
                                    setBulkIds={setBulkIds}
                                    tabIdToSend={tabIdToSend}
                                    columnsHeader={columnsHeader}
                                    columnActions={columnActions}
                                    inputFields={inputFields}
                                    delayedCall={delayedCall}
                                    setInputFields={setInputFields}
                                    expandableColumnsHeader={
                                      expandableColumnsHeader
                                    }
                                    columnDataActions={columnDataActions}
                                    bulkActionLength={bulkActions?.length}
                                  />
                                ))
                              )}
                            </TableBody>
                          </>
                        </Table>
                      </TableContainer>
                      <Modal
                        show={show1}
                        onHide={ModalhandleClose1}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Header
                          closeButton
                          className="bg-light-primary m-0 p-5"
                        >
                          <h4>{t("Delete Record")}</h4>
                        </Modal.Header>
                        <Modal.Body>
                          {t("Are you sure you want to delete this record ?")}
                        </Modal.Body>
                        <Modal.Footer className="p-0">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={ModalhandleClose1}
                          >
                            {t("Cancel")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger m-2"
                            onClick={() => {
                              ModalhandleClose1();
                              DeletebyId(value);
                            }}
                          >
                            {t("Delete")}
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    {/* <CustomPagination
                      curPage={curPage}
                      nextPage={nextPage}
                      pageNumbers={pageNumbers}
                      pageSize={pageSize}
                      prevPage={prevPage}
                      showPage={showPage}
                      total={total}
                      totalPages={totalPages}
                    /> */}
                  </Box>
                  <div className="d-flex gap-3 mt-4">
                    {bottomButtonsPane1.map(
                      ({
                        actionHtml,
                        actionUrl,
                        methodType,
                        actionName,
                      }: any) => {
                        const isDisabled =
                          (actionName === "Next Button" &&
                            rows?.[0]?.NextRecordNumber === 0) ||
                          (actionName === "Previous Button" &&
                            rows?.[0]?.PreviousRecordNumber === 0);

                        return (
                          <span
                            style={{
                              opacity: isDisabled ? "0.5" : "1",
                            }}
                            dangerouslySetInnerHTML={{ __html: actionHtml }}
                            onClick={
                              isDisabled
                                ? undefined
                                : async () => {
                                    try {
                                      const response =
                                        await PatientServices.makeApiCallForDynamicGrid(
                                          actionUrl,
                                          methodType ?? null,
                                          {
                                            recordNumber:
                                              actionName === "Next Button"
                                                ? rows[0].NextRecordNumber
                                                : rows[0].PreviousRecordNumber,
                                            filters,
                                          }
                                        );

                                      setRows(response.data.pane2);
                                      setRows2(response.data.pane1);
                                    } catch (error) {
                                      console.error(error);
                                    }
                                  }
                            }
                          />
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightPanel;

export const TabSelected = styled(Tab)(AutocompleteStyle());

export function a11yProps(index: string, sortorder: string) {
  return {
    id: index,
    sortorder,
    "aria-controls": `simple-tabpanel-${index}-${sortorder}`,
  };
}
