import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { styles } from "../../Utils/Common";

const ProviderDropdown = (props: any) => {
  const inputElementReactSelect = useRef(props?.name);
  const [selectedProvider, setSelectedProvider] = useState(null);
  useEffect(() => {
    if (inputElementReactSelect.current) {
      if (props.errorFocussedInput === inputElementReactSelect.current.id) {
        inputElementReactSelect.current.focus();
      }
    }
  }, [props.errorFocussedInput]);

  useEffect(() => {
    if (props.physicianArr?.length === 1) {
      setSelectedProvider(props.physicianArr[0]);
      props.onProviderSelect(props.physicianArr[0]);
      sessionStorage.setItem("PhysicianID", props.physicianArr[0].value);
    }
  }, [props.physicianArr.length === 1]);
  useEffect(() => {
    setSelectedProvider(props.selectedProviders);
  }, [props.selectedProviders]);
  const handleProviderSelect = (selectedOption: any) => {
    // selectedOption.preventDefault();
    console.log(selectedOption, "Provider");
    setSelectedProvider(selectedOption);
    props.onProviderSelect(selectedOption);
    props.setPhysicianId(selectedOption?.value);
    sessionStorage.setItem("PhysicianID", selectedOption?.value);
  };
  return (
    <>
      <div id={props.name} ref={inputElementReactSelect} tabIndex={-1}></div>
      <Select
        menuPortalTarget={document.body}
        options={props.physicianArr}
        theme={(theme) => styles(theme)}
        value={selectedProvider}
        onChange={handleProviderSelect}
        isSearchable={true}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: "var(--kt-input-border-color)",
            color: "var(--kt-input-border-color)",
          }),
        }}
      />
    </>
  );
};

export default ProviderDropdown;
