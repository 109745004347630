import HttpClient from "../../HttpClient.ts";
import apiRoutes from "../../Routes/Routes.json";

const getPatientDemographicsList = (searchRequest) => {
  return HttpClient().post(
    `api/PatientManagement/GetAllPatientsV2`,
    searchRequest
  );
};
const ViewPatientDemoGraphics = (obj) => {
  return HttpClient().post(`api/PatientManagement/ViewPatient`, obj);
};
const getPatientRequisitionOrder = (patientId) => {
  return HttpClient().get(
    `api/PatientManagement/Patient/${patientId}/Requisitions`
  );
};

const PatientService = {
  getPatientDemographicsList,
  getPatientRequisitionOrder,
  ViewPatientDemoGraphics,
};

export default PatientService;
