import { useEffect, useRef, useState } from "react";
import {
  panelsArrItemAddChild,
  panelsArrItemRemoval,
  panelsArrItemRemovalChild,
  panelsArrMakerToSend,
} from "../../../Utils/Common/Requisition";
import { assignFormValues } from "../../../Utils/Auth";
import { t } from "i18next";
const ToxTestingOption = (props: any) => {
  const [panelsArrToSend, setPanelsArrToSend] = useState<any>([]);
  const inputElement = useRef(props?.name);
  useEffect(() => {
    setpanelsArrToSendForEdit();
  }, [props.panels]);
  useEffect(() => {
    if (
      props?.errorFocussedInput === inputElement?.current ||
      props?.errorFocussedInput === inputElement?.current?.id
    ) {
      inputElement?.current?.focus();
    }
  }, [props?.errorFocussedInput]);
  function FindIndex(arr: any[], rid: any) {
    return arr.findIndex((i: any) => i.reqId === rid);
  }
  const handleChangeParent = (
    panelID: number,
    panelName: string,
    checked: boolean,
    index: number
  ) => {
    const name = panelName;
    let panelsArrToAppendCopy = [...panelsArrToSend];
    let inputsCopy = JSON?.parse(JSON?.stringify(props?.Inputs));
    if (checked) {
      props.Inputs[props.index].fields[props?.fieldIndex].panels[
        index
      ].isSelected = true;
      props.Inputs[props.index].fields[props?.fieldIndex].panels[
        index
      ].testOptions.forEach((testOptions: any) => {
        testOptions.isSelected = true;
      });
      let finalisedPanelsArrToSend = panelsArrMakerToSend(
        index,
        props?.Inputs[props.index]?.fields[props?.fieldIndex]?.panels[index],
        panelsArrToAppendCopy,
        checked
      );
      setPanelsArrToSend(finalisedPanelsArrToSend);
      let newInputs = assignFormValues(
        props?.Inputs,
        props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        finalisedPanelsArrToSend,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      newInputs?.then((res) => {
        let infectiousDataCopy = JSON?.parse(
          JSON?.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props?.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData([...infectiousDataCopy]);
      });
    }

    if (!checked) {
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].isSelected = false;
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].testOptions.forEach((testOptions: any) => {
        testOptions.isSelected = false;
      });
      let finalisedPanelsArrToSend = panelsArrItemRemoval(
        panelID,
        panelsArrToAppendCopy
      );
      setPanelsArrToSend(finalisedPanelsArrToSend);
      let newInputs = assignFormValues(
        inputsCopy,
        props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        finalisedPanelsArrToSend,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      newInputs?.then((res) => {
        let infectiousDataCopy = JSON?.parse(
          JSON?.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props?.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData([...infectiousDataCopy]);
      });
    }
  };
  const handleChangeChild = (
    testID: string,
    checked: boolean,
    parentPanelName: string,
    index: number,
    childIndex: number,
    panelID: any
  ) => {
    let inputsCopy = JSON.parse(JSON?.stringify(props?.Inputs));
    let panelsArrToAppendCopy = JSON.parse(JSON?.stringify(panelsArrToSend));
    let panelsCopy = [...props?.panels];
    if (checked) {
      // Update child checkbox state
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].testOptions[childIndex].isSelected = true;
      // Update parent checkbox state
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].isSelected = true;
      // Update panelsArrToSend state
      let finalisedPanelsArrToSend = panelsArrItemAddChild(
        index,
        parentPanelName,
        [
          inputsCopy[props.index].fields[props?.fieldIndex].panels[index]
            .testOptions[childIndex],
        ],
        panelsArrToAppendCopy,
        panelsCopy
      );
      setPanelsArrToSend(finalisedPanelsArrToSend);
    } else {
      // Uncheck the child checkbox
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].testOptions[childIndex].isSelected = false;
      // Check if all child checkboxes in the parent panel are unchecked
      const allChildUnchecked = inputsCopy[props.index].fields[
        props?.fieldIndex
      ].panels[index].testOptions.every((option: any) => !option.isSelected);
      // If all child checkboxes are unchecked, update parent checkbox state and panel visibility
      if (allChildUnchecked) {
        // Uncheck the parent checkbox
        inputsCopy[props.index].fields[props?.fieldIndex].panels[
          index
        ].isSelected = false;
        // Update parent panel visibility
      }
      // Update panelsArrToSend state
      let finalisedPanelsArrToSend = panelsArrItemRemovalChild(
        index,
        testID,
        panelsArrToAppendCopy
      );
      setPanelsArrToSend(finalisedPanelsArrToSend);
    }
    // Update inputs and infectiousData states
    let newInputs = assignFormValues(
      inputsCopy,
      props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      panelsArrToAppendCopy,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );
    newInputs?.then((res) => {
      let infectiousDataCopy = JSON?.parse(
        JSON?.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props?.ArrayReqId)
      ].sections = res;
      props?.setInfectiousData([...infectiousDataCopy]);
    });
  };

  const setpanelsArrToSendForEdit = () => {
    let panelsCopy = [...props?.panels];
    let filteredPanelsCopy = panelsCopy?.filter(
      (panelsData: any) => panelsData?.isSelected
    );
    let panelNameArr: any = [];
    filteredPanelsCopy?.forEach((panelData: any) => {
      panelNameArr?.push(panelData?.panelName);
    });
    let inputsCopy = [...props?.Inputs];
    assignFormValues(
      inputsCopy,
      props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      filteredPanelsCopy,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );
    setPanelsArrToSend(filteredPanelsCopy);
  };
  return (
    <>
      {props.error && (
        <div className="form__error">
          <span>{t(props.error)}</span>
        </div>
      )}
      <div id={props?.name} ref={inputElement} tabIndex={-1}></div>
      {props?.panels?.map((options: any, index: number) => (
        <>
          {options.isVisible && (
            <div
              className={`${
                options.panelName === "Screening"
                  ? "col-sm-12 col-md-12 col-xl-12 col-xxl-12"
                  : `${props?.displayType}`
              }`}
            >
              <div className="row">
                {/* ***************** 1/4 ****************** */}
                <div className="col-lg-12">
                  <div className="card shadow-sm mb-3 rounded">
                    <div
                      className="card-header d-flex justify-content-between align-items-center rounded min-h-35px px-4"
                      style={{ background: "#F3F6F9", borderRadius: "8px" }}
                    >
                      <div className="col-12">
                        <label className="form-check form-check-sm form-check-solid col-12">
                          <input
                            className="form-check-input mr-2 h-20px w-20px"
                            type="checkbox"
                            checked={options?.isSelected}
                            onChange={(e: any) => {
                              handleChangeParent(
                                options?.panelID,
                                options?.panelName,
                                e.target.checked,
                                index
                              );
                            }}
                          />
                          {<span className="fw-600">{options?.panelName}</span>}
                        </label>
                      </div>
                    </div>
                    <div className="card-body py-md-4 py-3 col-12">
                      <div className="row">
                        {options?.testOptions?.map(
                          (optionsChild: any, childIndex: any) => (
                            <>
                              <div className="mb-3 col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                <label className="form-check form-check-sm align-items-start form-check-solid col-12">
                                  <input
                                    className="form-check-input mr-2 h-20px w-20px"
                                    type="checkbox"
                                    onChange={(e: any) => {
                                      handleChangeChild(
                                        optionsChild?.testID,
                                        e?.target?.checked,
                                        options?.panelName,
                                        index,
                                        childIndex,
                                        options?.panelID
                                      );
                                    }}
                                    checked={
                                      options?.isSelected &&
                                      optionsChild?.isSelected
                                    }
                                  />
                                  <span className="fw-400">
                                    {optionsChild?.testName}
                                  </span>
                                </label>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
};

export default ToxTestingOption;
