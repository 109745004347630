import React, { useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import RequisitionType from "../../Services/Requisition/RequisitionTypeService";
import { AxiosError, AxiosResponse } from "axios";
import useForm from "../hooks/useForm";
import { facilityProviderInitialState } from "../../Pages/Admin/InitialState";
import validate from "../../Utils/validate";
import { toast } from "react-toastify";
import { assignFormValues } from "../../Utils/Auth";
import { extractDropDownSelectedValue } from "../../Utils/Common/Requisition";
import { t } from "i18next";
export const genderChoices = [
  {
    id: "Male",
    label: "Male",
    value: "Male",
  },
  {
    id: "Female",
    label: "Female",
    value: "Female",
  },
  {
    id: "Unknown",
    label: "Unknown",
    value: "Unknown",
  },
  {
    id: "Intersex",
    label: "Intersex",
    value: "Intersex",
  },
];
interface CollapseProps {
  open: boolean;
  handleClose?: any;
  isShown: any;
  setIsShown: any;
  inputs: any;
  setInputs: any;
  index: number;
  selectedDropDownValue: any;
  setSelectedDropDownValue: any;
  dependenceyControls: any;
  setPhysicianList: any;
  physicianList: any;
  onFacilitySelect: any;
  fields: any;
  getCollectorNameList: any;
}

const Popup = (props: CollapseProps) => {
  const [loading, setLoading] = useState({
    search: false,
    save: false,
  });
  // const [formData, setFormData] = useState({
  //   npi: "",
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   sex: "",
  // });
  const { formData, setFormData, changeHandler, errors, setErrors }: any =
    useForm(facilityProviderInitialState, validate);

  const onBlur = () => {
    setLoading((preVal: any) => {
      return {
        ...preVal,
        search: true,
      };
    });
    console.log(formData.npi.value, "formdatanpi");
    RequisitionType.searchNpi(formData.npi.value)
      .then((res: AxiosResponse) => {
        setFormData((preVal: any) => {
          return {
            ...preVal,
            firstName: {
              ...formData["firstName"],
              value: res?.data?.FirstName,
            },
            lastName: {
              ...formData["lastName"],
              value: res?.data?.LastName,
            },
          };
        });
      })
      .catch((err: AxiosError) => console.trace(err))
      .finally(() =>
        setLoading((preVal: any) => {
          return {
            ...preVal,
            search: false,
          };
        })
      );
  };
  const onFacilitySelect = (e: any) => {
    if (!e?.value) return;
    RequisitionType.getProvidersList(e.value)
      .then((res: any) => {
        props.setPhysicianList(res?.data);
      })
      .catch((err: any) => {
        console.trace(err);
      });
    props.getCollectorNameList(e.value);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let facilityID = localStorage.getItem("facilityID");
    formData.facilities.value = facilityID;
    let size;
    let formErrors: any;
    formErrors = validate(formData, true);
    setErrors(formErrors);
    size = Object.keys(formErrors).length;
    if (size !== 0) {
      toast.error("Please fill the required fields!");
      return;
    }
    formData.facilities.value = [facilityID];
    let objToSend = {
      npi: formData.npi.value,
      firstName: formData.firstName.value,
      lastName: formData.lastName.value,
      sex: formData.sex.value,
      email: formData.email.value,
      facilities: formData.facilities.value,
    };
    setLoading((preVal: any) => {
      return {
        ...preVal,
        save: true,
      };
    });
    await RequisitionType.CreateFacilityProvider(objToSend)
      .then((res: any) => {
        if (res.data.statusCode === 400) {
          toast.error(res.data.message);
          return;
        }
        let inputsCopy = [...props?.inputs];
        let facilityIndex = inputsCopy[props.index].fields.findIndex(
          (items: any) => (items.systemFieldName = "FacilityID")
        );
        inputsCopy[props.index].fields[facilityIndex].options.push(
          res?.data?.data
        );
        props.setSelectedDropDownValue((preVal: any) => {
          return {
            ...preVal,
            selectedProvider: res?.data?.data,
          };
        });
        props.setInputs(inputsCopy);

        setFormData({
          npi: {
            ...formData["npi"],
            value: "",
          },
          firstName: {
            ...formData["firstName"],
            value: "",
          },
          lastName: {
            ...formData["lastName"],
            value: "",
          },
          sex: {
            ...formData["sex"],
            value: "",
          },
          email: {
            ...formData["email"],
            value: "",
          },
          facilities: {
            ...formData["facilities"],
            value: "",
          },
        });
        assignFormValues(
          props?.inputs,
          props?.dependenceyControls,
          props?.index,
          undefined,
          props.selectedDropDownValue.selectedFacility,
          res?.data?.data.value,
          false,
          false,
          false,
          undefined,
          undefined,
          undefined,
          res?.data?.data?.label,
          props?.setInputs
        );
        assignFormValues(
          props?.inputs,
          props?.dependenceyControls,
          props?.index,
          undefined,
          1,
          res?.data?.data.value,
          false,
          false,
          false,
          undefined,
          undefined,
          undefined,
          res?.data?.data?.label,
          props?.setInputs
        );
        let selectedFacility = extractDropDownSelectedValue(
          props.fields,
          "Facility"
        );
        onFacilitySelect(selectedFacility);
        props.handleClose();
      })
      .catch((err: any) => {
        console.trace(err, "err");
      })
      .finally(() => {
        setLoading((preVal: any) => {
          return {
            ...preVal,
            save: false,
          };
        });
      });
  };
  const closeModal = () => {
    props?.handleClose();
    setFormData({
      npi: "",
      firstName: "",
      lastName: "",
      email: "",
      sex: "",
    });
    props?.setIsShown(!props?.isShown);
  };
  const handleGender = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setFormData({ ...formData, sex: { value: selectedValue } });
  };
  return (
    <Collapse in={props.open}>
      <Box>
        <div className="card px-0">
          {/* ************************** Demo Body *********************** */}
          <div
            className="card-header min-h-30px mt-3 mt-0 px-0"
            id="kt_engage_demos_header"
          >
            <h3 className="m-0 fs-15px">{t("Add New Provider")}</h3>
          </div>
          <div className="card-body px-0 pb-2 pt-4">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
              <div className="row m-0">
                <div className="mb-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                  <label className="required mb-2">{t("NPI #")}</label>
                  <input
                    type="tel"
                    name="npi"
                    className="form-control bg-transparent mb-3 mb-lg-0"
                    placeholder={loading.search ? t("...loading") : t("NPI #")}
                    value={formData?.npi?.value ?? ""}
                    onChange={changeHandler}
                    minLength={10}
                    maxLength={10}
                  />
                  {errors.npi && (
                    <div className="form__error">
                      <span>{t(errors.npi)}</span>
                    </div>
                  )}
                </div>
                <div className="mt-8 col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                  <button
                    onClick={onBlur}
                    type="button"
                    className="btn btn-light btn-sm px-4 mx-2 p-2 text-info py-3"
                    disabled={loading.search}
                  >
                    {loading.search ? t("Searching") : t("Search NPI Records")}
                  </button>
                </div>
                <div className="mb-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                  <label className="required mb-2">{t("First Name")}</label>
                  <input
                    type="text"
                    name="firstName"
                    className="form-control bg-transparent mb-3 mb-lg-0"
                    placeholder={
                      loading.search ? t("...loading") : t("First Name")
                    }
                    value={formData?.firstName?.value ?? ""}
                    onChange={changeHandler}
                  />
                  {errors.firstName && (
                    <div className="form__error">
                      <span>{t(errors.firstName)}</span>
                    </div>
                  )}
                </div>
                <div className="mb-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                  <label className="required mb-2">{t("Last Name")}</label>
                  <input
                    type="text"
                    name="lastName"
                    className="form-control bg-transparent mb-3 mb-lg-0"
                    placeholder={
                      loading.search ? t("...loading") : t("Last Name")
                    }
                    value={formData?.lastName?.value ?? ""}
                    onChange={changeHandler}
                  />
                  {errors.lastName && (
                    <div className="form__error">
                      <span>{t(errors.lastName)}</span>
                    </div>
                  )}
                </div>

                <div className="mb-4 col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <label className="required mb-2 fw-500">{t("Gender")}</label>
                  <div className="row m-0">
                    {genderChoices.map((choice) => (
                      <label
                        key={choice.id}
                        className="form-check form-check-sm align-items-start form-check-solid col-6 my-1"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sex"
                          id={choice.id}
                          value={choice.value}
                          checked={formData.sex.value === choice.value}
                          onChange={handleGender}
                        />
                        <span className="form-check-label text-break">
                          {t(choice.label)}
                        </span>
                      </label>
                    ))}
                    {errors.sex && (
                      <div className="form__error">
                        <span>{t(errors.sex)}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                  <label className="required mb-2">{t("Email")}</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control bg-transparent mb-3 mb-lg-0"
                    placeholder={loading.search ? t("...loading") : t("Email")}
                    value={formData?.email?.value ?? ""}
                    onChange={changeHandler}
                  />
                  {errors.email && (
                    <div className="form__error">
                      <span>{t(errors.email)}</span>
                    </div>
                  )}
                </div>
                {errors.facilities && (
                  <div className="form__error">
                    <span>{t(errors.facilities)}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-footer px-0 py-2">
            <button
              type="button"
              className="btn btn-secondary btn btn-primary"
              onClick={closeModal}
            >
              {t("Cancel")}
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary m-2"
              disabled={loading.save}
            >
              {loading.save ? t("Saving") : t("Save")}
            </button>
          </div>
        </div>
      </Box>
    </Collapse>
  );
};

export default Popup;
