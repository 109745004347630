import { LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import DynamicSplitPane from "Pages/DynamicSplitPane";
import ViewPatientDemographics from "Pages/Patient/ViewPatientDemographics";
import WorkLog from "Pages/Requisition/WorkLog";
import { connect } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import AccountSettings from "../../Pages/AccountSettings";
import AddUserRoles from "../../Pages/Admin/AddUserRoles";
import EditReferenceLab from "../../Pages/Admin/ReferenceLab/AddReferenceLab/EditReferenceLab";
import ChangePassword from "../../Pages/Auth/ChangePassword";
import ChangeSecurityQuestions from "../../Pages/Auth/SecurityQuestions/ChangeSecurityQuestions";
import SecurityQuestions from "../../Pages/Auth/SecurityQuestions/SecurityQuestions";
import BloodLISSetting from "../../Pages/Blood/BloodLIS/LIS";
import PanelType from "../../Pages/Compendium/Panel Type";
import Index from "../../Pages/Compendium/TestType";
import { DynamicForm } from "../../Pages/DynamicForm";
import DynamicGrid from "../../Pages/DynamicGrid";
import DynamicOneUi from "../../Pages/DynamicOneUi";
import EditFacility from "../../Pages/Facility/EditFacility";
import ViewSingleFacilityForApproval from "../../Pages/Facility/FacilityApproval/ViewSingleFacilityForApproval";
import AddFacilityUser from "../../Pages/Facility/FacilityUser/CreateFacilityUser";
import EditFacilityUser from "../../Pages/Facility/FacilityUser/CreateFacilityUser/EditFacilityUser";
import View from "../../Pages/Facility/FacilityUser/View";
import ViewSingleFacility from "../../Pages/Facility/ViewSingleFacility";
import MyFavoriteMenu from "../../Pages/Lab/MyFavoriteMenu";
import ManageSales from "../../Pages/ManageSalesRep";
import BulletinBoard from "../../Pages/Marketing/BulletinBoard";
import AddPatient from "../../Pages/Patient/PatientDemographic";
import EditPatient from "../../Pages/Patient/PatientDemographic/EditPatient";
import RequisitionReport from "../../Pages/Requisition/RequisitionReport";
import RequisitionSummary from "../../Pages/Requisition/SingleRequisition/RequisitionSummary";
import OrderView from "../../Pages/Requisition/ViewOfOrderRequisition";
import SalesRepView from "../../Pages/SalesManagements/Pending/View";
import SalesRepRequest from "../../Pages/SalesManagements/SalesRepRequest";
import UpsPickupAndShipment from "../../Pages/ShippingPickup/PickupAndShipment";
import ShippingInformation from "../../Pages/SupplyManagement/ManageOrder/NewOrders/ShippingInformation";
import ViewOrders from "../../Pages/SupplyManagement/ManageOrder/ViewOrders";
import TrainingAidsMain from "../../Pages/TrainingAidMarketing/TrainingAids/TrainingAidsMain";
import RoutesObj from "../../Routes/Routes";
import ability from "../../Shared/Common/Permissions/Abilities";
import { getTokenData } from "../../Utils/Auth";
import AuthRoutes from "../../Utils/Auth/RouteDirection";
import { GetSingleElementFromArray } from "../../Utils/Common/CommonMethods";
import AsideMenu from "../Common/AsideMenu";
import { SideDrawers } from "../Common/SideDrawers";
import TopHeader from "../Common/TopHeader";

function TrueMedDashboardLayout(props: any) {
  const location = useLocation();
  const userInfo = getTokenData();
  let permissions: any = [];
  if (
    typeof userInfo !== "undefined" &&
    typeof userInfo.authTenants !== "undefined"
  ) {
    let selectedTenantInfo = GetSingleElementFromArray(
      userInfo.authTenants,
      (x: any) => x.isSelected === true
    );
    permissions = selectedTenantInfo?.infomationOfLoggedUser?.permissions;
  }
  ability.update(permissions);
  const StyledLinearProgress = styled(LinearProgress)<any>(({ theme }) => ({
    "&.MuiLinearProgress-root": {
      backgroundColor: "#fff",
      height: "2px",
      "& .MuiLinearProgress-bar": {
        backgroundColor: "red",
      },
    },
  }));
  return (
    <div className="d-flex flex-column flex-root app-root">
      <div className="app-page flex-column flex-column-fluid">
        <StyledLinearProgress className="d-none" />
        <TopHeader />
        <div
          className="app-wrapper flex-column flex-row-fluid"
          id="kt_app_wrapper"
        >
          <AsideMenu />
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <Routes>
              {location.pathname.includes("ChangePassword") ? (
                <Route path="ChangePassword/:id" element={<ChangePassword />} />
              ) : location.pathname.includes("MyFavourite") ? (
                <Route path="/MyFavourite" element={<MyFavoriteMenu />} />
              ) : (
                <>
                  <Route path="/" element={<MyFavoriteMenu />} />
                  {/* {getAuthRoutes(RoutesObj.AuthRoutes, props.User.Menus)} */}
                  <Route
                    path="*"
                    element={
                      <AuthRoutes
                        routesArr={RoutesObj.AuthRoutes}
                        menus={props.User.Menus}
                      />
                    }
                  />
                </>
              )}
              <Route path="patient" element={<AddPatient />} />
              <Route path="patient/:patientId" element={<AddPatient />} />
              <Route
                path="pickup-shipment/:courierName"
                element={<UpsPickupAndShipment />}
              />
              <Route path="facility-user-view-by-id/:id" element={<View />} />
              <Route
                path="OrderView/:requisitionId/:requisitionOrderId"
                element={<OrderView />}
              />
              <Route
                path="facility-view/:id"
                element={<ViewSingleFacility />}
              />
              <Route
                path="view-patient-demographics-patient/:id"
                element={<ViewPatientDemographics />}
              />
              <Route path="supply-order-view" element={<ViewOrders />} />
              <Route path="supply-order" element={<ShippingInformation />} />
              <Route
                path="facility-view-approval/:id"
                element={<ViewSingleFacilityForApproval />}
              />
              <Route
                path="edit-facility-user/:id"
                element={<EditFacilityUser />}
              />
              <Route path="editfacility/:id" element={<EditFacility />} />
              <Route
                path="RequisitionSummary"
                element={<RequisitionSummary />}
              />
              <Route path="add-user-roles" element={<AddUserRoles />} />
              <Route path="editpatient/:id" element={<EditPatient />} />
              <Route
                path="edit-reference-lab/:id"
                element={<EditReferenceLab />}
              />{" "}
              <Route path="add-facility-user" element={<AddFacilityUser />} />
              <Route path="bulletin-board" element={<BulletinBoard />} />
              <Route path="training-documents" element={<TrainingAidsMain />} />
              <Route path="Manage-Sales-Rep" element={<ManageSales />} />
              <Route path="sales-Rep-Request" element={<SalesRepRequest />} />
              <Route
                path="salesUser-view-by-id/:id"
                element={<SalesRepView />}
              />
              <Route path="panel-type" element={<PanelType />} />
              <Route path="test-type" element={<Index />} />
              <Route path="dynamic-form/:pageId" element={<DynamicForm />} />
              <Route path="dynamic-grid/:pageId" element={<DynamicGrid />} />
              <Route path="dynamic-one-ui/:pageId" element={<DynamicOneUi />} />
              <Route path="Blood-LIS-Setting" element={<BloodLISSetting />} />
              <Route
                path="Requisition-Report"
                element={<RequisitionReport />}
              />
              <Route
                path="security/password"
                element={<ChangeSecurityQuestions />}
              />
              <Route
                path="security/change-questions"
                element={<SecurityQuestions />}
              />
              <Route path="account-settings" element={<AccountSettings />} />
              <Route path="dynamic-split-pane" element={<DynamicSplitPane />} />
              <Route path="/view-requisition/work-log" element={<WorkLog />} />
            </Routes>
          </div>
        </div>
        <SideDrawers />
      </div>
    </div>
  );
}

function mapStateToProps(state: any) {
  return { User: state.Reducer };
}
export default connect(mapStateToProps)(TrueMedDashboardLayout);
