import { useEffect, useRef, useState } from "react";
import {
  getSpecimenSourceIndex,
  panelsArrItemAddChild,
  panelsArrItemRemoval,
  panelsArrItemRemovalChild,
  panelsArrMakerToSend,
  getICDPanelsIndex,
} from "../../../Utils/Common/Requisition";
import { assignFormValues } from "../../../Utils/Auth";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
const PanelsCheckboxSelected = (props: any) => {
  const location = useLocation();
  const [panelsArrToSend, setPanelsArrToSend] = useState<any>([]);
  const inputElement = useRef(props?.name);
  useEffect(() => {
    setpanelsArrToSendForEdit();
  }, [props.panels]);
  useEffect(() => {
    if (
      props?.errorFocussedInput === inputElement?.current ||
      props?.errorFocussedInput === inputElement?.current?.id
    ) {
      inputElement?.current?.focus();
    }
  }, [props?.errorFocussedInput]);
  function FindIndex(arr: any[], rid: any) {
    return arr.findIndex((i: any) => i.reqId === rid);
  }
  const handleChangeParent = (
    panelID: number,
    panelName: string,
    checked: boolean,
    index: number
  ) => {
    const name = panelName;
    let panelsArrToAppendCopy = [...panelsArrToSend];
    let inputsCopy = JSON?.parse(JSON?.stringify(props?.Inputs));
    let PanelIdRemove;
    if (checked) {
      let specimenSourceIndex = getSpecimenSourceIndex(inputsCopy);
      let ICDPanelIndex = getICDPanelsIndex(inputsCopy);
      if (specimenSourceIndex) {
        inputsCopy[specimenSourceIndex]?.fields?.forEach(
          (specimenSourceFields: any) => {
            specimenSourceFields?.specimenSources?.forEach(
              (panelsInfo: any) => {
                panelsInfo.isVisible = false;
              }
            );
          }
        );
      }
      if (ICDPanelIndex) {
        inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
          if (IcdPanelsFields.systemFieldName == "ICDPanels") {
            IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
              panelsInfo.isVisible = false; // Set all ICDPanels to false initially
            });
          }
        });
      }

      // Now set the clicked ICD panel's visibility to true
      if (ICDPanelIndex) {
        inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
          if (IcdPanelsFields.systemFieldName == "ICDPanels") {
            IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
              if (panelsInfo.panelName == name) {
                panelsInfo.isVisible = true; // Set clicked ICD panel to true
              }
            });
          }
        });
      }
      //checking that only one parent checkbox is selected at same time
      inputsCopy[props.index]?.fields[props?.fieldIndex]?.panels?.forEach(
        (panel: any, i: number) => {
          if (i !== index) {
            panel.isSelected = false;
            PanelIdRemove = panel?.panelID;
            panel?.testOptions?.forEach((testOption: any) => {
              testOption.isSelected = false;
            });
          }
        }
      );
      if (PanelIdRemove) {
        let new_Vanished = panelsArrItemRemoval(
          PanelIdRemove,
          panelsArrToAppendCopy
        );
        let newInputs = assignFormValues(
          inputsCopy,
          props?.dependenceyControls,
          props?.index,
          props?.depControlIndex,
          props?.fieldIndex,
          new_Vanished,
          props?.isDependency,
          props?.repeatFieldSection,
          props?.isDependencyRepeatFields,
          props?.repeatFieldIndex,
          props?.repeatDependencySectionIndex,
          props?.repeatDepFieldIndex,
          undefined,
          props?.setInputs
        );
        newInputs?.then((res) => {
          let infectiousDataCopy = JSON?.parse(
            JSON?.stringify(props?.infectiousData)
          );
          infectiousDataCopy[
            FindIndex(props?.infectiousData, props?.ArrayReqId)
          ].sections = res;
          props?.setInfectiousData([...infectiousDataCopy]);
        });
      }
      //starting of specimen source
      if (specimenSourceIndex) {
        inputsCopy[specimenSourceIndex]?.fields?.forEach(
          (specimenSourceFields: any) => {
            specimenSourceFields?.specimenSources?.forEach(
              (panelsInfo: any) => {
                if (panelsInfo?.panelName === name) {
                  panelsInfo.isVisible = true;
                } else {
                  panelsInfo.isVisible = false;
                }
              }
            );
          }
        );
      }

      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].isSelected = true;
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].testOptions.forEach((testOptions: any) => {
        testOptions.isSelected = true;
      });
      let finalisedPanelsArrToSend = panelsArrMakerToSend(
        index,
        inputsCopy[props.index]?.fields[props?.fieldIndex]?.panels[index],
        panelsArrToAppendCopy,
        checked
      );
      setPanelsArrToSend(finalisedPanelsArrToSend);
      let newInputs = assignFormValues(
        inputsCopy,
        props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        finalisedPanelsArrToSend,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      newInputs?.then((res) => {
        let infectiousDataCopy = JSON?.parse(
          JSON?.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props?.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData([...infectiousDataCopy]);
      });
    }

    if (!checked) {
      let specimenSourceIndex = getSpecimenSourceIndex(inputsCopy);
      let ICDPanelIndex = getICDPanelsIndex(inputsCopy);
      inputsCopy[specimenSourceIndex]?.fields.forEach(
        (specimenSourceFields: any) => {
          specimenSourceFields.specimenSources?.forEach((panelsInfo: any) => {
            if (panelsInfo.panelName === name) {
              panelsInfo.isVisible = false;
            }
          });
        }
      );
      if (ICDPanelIndex) {
        inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
          if (IcdPanelsFields.systemFieldName == "ICDPanels") {
            IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
              if (panelsInfo.panelName == name) {
                panelsInfo.isVisible = false; // Set clicked ICD panel to false
              }
            });
          }
        });
      }
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].isSelected = false;
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].testOptions.forEach((testOptions: any) => {
        testOptions.isSelected = false;
      });
      let finalisedPanelsArrToSend = panelsArrItemRemoval(
        panelID,
        panelsArrToAppendCopy
      );
      setPanelsArrToSend(finalisedPanelsArrToSend);
      let newInputs = assignFormValues(
        inputsCopy,
        props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        finalisedPanelsArrToSend,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      newInputs?.then((res) => {
        let infectiousDataCopy = JSON?.parse(
          JSON?.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props?.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData([...infectiousDataCopy]);
      });
    }
  };

  const handleChangeChild = (
    testID: string,
    checked: boolean,
    parentPanelName: string,
    index: number,
    childIndex: number,
    panelID: any
  ) => {
    let PanelIdRemove;
    const name = parentPanelName;
    let inputsCopy = JSON.parse(JSON?.stringify(props?.Inputs));
    let panelsArrToAppendCopy = JSON.parse(JSON?.stringify(panelsArrToSend));
    let panelsCopy = [...props?.panels];
    if (checked) {
      let ICDPanelIndex = getICDPanelsIndex(inputsCopy);
      let specimenSourceIndex = getSpecimenSourceIndex(inputsCopy);
      inputsCopy[specimenSourceIndex]?.fields?.forEach(
        (specimenSourceFields: any) => {
          specimenSourceFields?.specimenSources?.forEach((panelsInfo: any) => {
            panelsInfo.isVisible = false;
          });
        }
      );
      if (ICDPanelIndex) {
        inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
          if (IcdPanelsFields.systemFieldName == "ICDPanels") {
            IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
              if (panelsInfo.panelName == name) {
                panelsInfo.isVisible = false; // Set clicked ICD panel to true
              }
            });
          }
        });
      }

      if (ICDPanelIndex) {
        inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
          if (IcdPanelsFields.systemFieldName == "ICDPanels") {
            IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
              if (panelsInfo.panelName == name) {
                panelsInfo.isVisible = true; // Set clicked ICD panel to true
              }
            });
          }
        });
      } // Unchecking the child checkboxes with different panel name
      inputsCopy[props.index].fields[props?.fieldIndex].panels.forEach(
        (panel: any, i: number) => {
          if (i !== index && panel?.panelName !== parentPanelName) {
            PanelIdRemove = panel?.panelID;
            panel.testOptions.forEach((option: any) => {
              option.isSelected = false;
              inputsCopy[specimenSourceIndex]?.fields?.forEach(
                (specimenSourceFields: any) => {
                  specimenSourceFields?.specimenSources?.forEach(
                    (panelsInfo: any) => {
                      if (panelsInfo?.panelName !== parentPanelName) {
                        panelsInfo.isVisible = false;
                      }
                    }
                  );
                }
              );
            });
            // Uncheck the parent checkbox if all child checkboxes are unchecked
            panel.isSelected = false;
          }
        }
      );
      if (PanelIdRemove) {
        let new_Vanished = panelsArrItemRemoval(
          PanelIdRemove,
          panelsArrToAppendCopy
        );
        let newInputs = assignFormValues(
          inputsCopy,
          props?.dependenceyControls,
          props?.index,
          props?.depControlIndex,
          props?.fieldIndex,
          new_Vanished,
          props?.isDependency,
          props?.repeatFieldSection,
          props?.isDependencyRepeatFields,
          props?.repeatFieldIndex,
          props?.repeatDependencySectionIndex,
          props?.repeatDepFieldIndex,
          undefined,
          props?.setInputs
        );

        newInputs?.then((res) => {
          let infectiousDataCopy = JSON?.parse(
            JSON?.stringify(props?.infectiousData)
          );
          infectiousDataCopy[
            FindIndex(props?.infectiousData, props?.ArrayReqId)
          ].sections = res;
          props?.setInfectiousData([...infectiousDataCopy]);
        });
      }
      // Update child checkbox state
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].testOptions[childIndex].isSelected = true;
      // Update parent checkbox state
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].isSelected = true;
      // Check if all child checkboxes in the parent panel are checked
      const allChildrenChecked = inputsCopy[props.index].fields[
        props?.fieldIndex
      ].panels[index].testOptions.every((option: any) => option.isSelected);
      // If all child checkboxes are checked, update parent panel visibility
      if (allChildrenChecked) {
        let specimenSourceIndex = getSpecimenSourceIndex(inputsCopy);
        inputsCopy[specimenSourceIndex].fields.forEach(
          (specimenSourceFields: any) => {
            specimenSourceFields.specimenSources.forEach((panelsInfo: any) => {
              if (panelsInfo.panelName === name) {
                panelsInfo.isVisible = true;
              }
            });
          }
        );
        inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
          if (IcdPanelsFields.systemFieldName == "ICDPanels") {
            IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
              if (panelsInfo.panelName == parentPanelName) {
                panelsInfo.isVisible = true;
              } else {
                panelsInfo.isVisible = false;
              }
            });
          }
        });
      }
      // Update panelsArrToSend state
      let finalisedPanelsArrToSend = panelsArrItemAddChild(
        index,
        parentPanelName,
        [
          inputsCopy[props.index].fields[props?.fieldIndex].panels[index]
            .testOptions[childIndex],
        ],
        panelsArrToAppendCopy,
        panelsCopy
      );
      setPanelsArrToSend(finalisedPanelsArrToSend);
    } else {
      // Uncheck the child checkbox
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].testOptions[childIndex].isSelected = false;
      // Check if all child checkboxes in the parent panel are unchecked
      const allChildUnchecked = inputsCopy[props.index].fields[
        props?.fieldIndex
      ].panels[index].testOptions.every((option: any) => !option.isSelected);
      // If all child checkboxes are unchecked, update parent checkbox state and panel visibility
      if (allChildUnchecked) {
        // Uncheck the parent checkbox
        inputsCopy[props.index].fields[props?.fieldIndex].panels[
          index
        ].isSelected = false;
        // Update parent panel visibility
        let specimenSourceIndex = getSpecimenSourceIndex(inputsCopy);

        inputsCopy[specimenSourceIndex].fields.forEach(
          (specimenSourceFields: any) => {
            specimenSourceFields.specimenSources.forEach((panelsInfo: any) => {
              if (panelsInfo.panelName === parentPanelName) {
                panelsInfo.isVisible = false;
              }
            });
          }
        );
        let ICDPanelIndex = getICDPanelsIndex(inputsCopy);
        if (ICDPanelIndex) {
          inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
            if (IcdPanelsFields.systemFieldName == "ICDPanels") {
              IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
                if (panelsInfo.panelName == name) {
                  panelsInfo.isVisible = false; // Set clicked ICD panel to true
                }
              });
            }
          });
        }
      }
      // Update panelsArrToSend state
      let finalisedPanelsArrToSend = panelsArrItemRemovalChild(
        index,
        testID,
        panelsArrToAppendCopy
      );
      setPanelsArrToSend(finalisedPanelsArrToSend);
    }

    // Update inputs and infectiousData states
    let newInputs = assignFormValues(
      inputsCopy,
      props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      panelsArrToAppendCopy,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );

    newInputs?.then((res) => {
      let infectiousDataCopy = JSON?.parse(
        JSON?.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props?.ArrayReqId)
      ].sections = res;
      props?.setInfectiousData([...infectiousDataCopy]);
    });
  };
  const setpanelsArrToSendForEdit = () => {
    let panelsCopy = [...props?.panels];
    let filteredPanelsCopy = panelsCopy?.filter(
      (panelsData: any) => panelsData?.isSelected
    );
    let panelNameArr: any = [];
    filteredPanelsCopy?.forEach((panelData: any) => {
      panelNameArr?.push(panelData?.panelName);
    });
    let inputsCopy = [...props?.Inputs];
    let specimenSourceIndex = getSpecimenSourceIndex(inputsCopy);
    inputsCopy[specimenSourceIndex]?.fields?.forEach(
      (specimenSourceFields: any) => {
        specimenSourceFields?.specimenSources?.forEach((panelsInfo: any) => {
          if (panelNameArr?.includes(panelsInfo.panelName)) {
            panelsInfo.isVisible = true;
          }
        });
      }
    );
    let ICDPanelIndex = getICDPanelsIndex(inputsCopy);
    inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
      if (IcdPanelsFields.systemFieldName == "ICDPanels") {
        IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
          if (panelNameArr?.includes(panelsInfo.panelName)) {
            panelsInfo.isVisible = true;
          }
        });
      }
    });

    assignFormValues(
      inputsCopy,
      props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      filteredPanelsCopy,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );

    // let infectiousDataCopy = JSON?.parse(
    //   JSON?.stringify(props?.infectiousData)
    // );
    // infectiousDataCopy[
    //   FindIndex(props?.infectiousData, props.ArrayReqId)
    // ].sections = inputsCopy;
    // props?.setInfectiousData([...infectiousDataCopy]);
    setPanelsArrToSend(filteredPanelsCopy);
  };

  return (
    <>
      {props.error && (
        <div className="form__error">
          <span>{t(props.error)}</span>
        </div>
      )}
      <div id={props?.name} ref={inputElement} tabIndex={-1}></div>
      {props?.panels?.map((options: any, index: number) => (
        <>
          {options.isVisible && (
            <div className={`${props?.displayType}`}>
              <div className="row">
                {/* ***************** 1/4 ****************** */}
                <div className="col-lg-12">
                  <div className="card shadow-sm mb-3 rounded">
                    <div
                      className="card-header d-flex justify-content-between align-items-center rounded min-h-35px px-4"
                      style={{ background: "#F3F6F9", borderRadius: "8px" }}
                    >
                      <div className="col-12">
                        <label className="form-check form-check-sm form-check-solid col-12">
                          <input
                            className="form-check-input mr-2 h-20px w-20px"
                            type="checkbox"
                            checked={options?.isSelected}
                            onChange={(e: any) => {
                              handleChangeParent(
                                options?.panelID,
                                options?.panelName,
                                e.target.checked,
                                index
                              );
                            }}
                            // disabled={
                            //   (location?.state?.reqId &&
                            //     location?.state?.Check) ||
                            //   !location?.state?.reqId ||
                            //   location?.state?.status === "Missing Info"
                            //     ? false
                            //     : true
                            // }
                          />
                          {<span className="fw-600">{options?.panelName}</span>}
                        </label>
                      </div>
                    </div>
                    <div className="card-body py-md-4 py-3 col-12">
                      <div className="row">
                        {options?.testOptions?.map(
                          (optionsChild: any, childIndex: any) => (
                            <>
                              <div className="mb-3 col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                <label className="form-check form-check-sm align-items-start form-check-solid col-12">
                                  <input
                                    className="form-check-input mr-2 h-20px w-20px"
                                    type="checkbox"
                                    onChange={(e: any) => {
                                      handleChangeChild(
                                        optionsChild?.testID,
                                        e?.target?.checked,
                                        options?.panelName,
                                        index,
                                        childIndex,
                                        options?.panelID
                                      );
                                    }}
                                    checked={
                                      options?.isSelected &&
                                      optionsChild?.isSelected
                                    }
                                    // disabled={
                                    //   (location?.state?.reqId &&
                                    //     location?.state?.Check) ||
                                    //   !location?.state?.reqId ||
                                    //   location?.state?.status === "Missing Info"
                                    //     ? false
                                    //     : true
                                    // }
                                  />
                                  <span className="fw-400">
                                    {optionsChild?.testName}
                                  </span>
                                </label>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
};

export default PanelsCheckboxSelected;
