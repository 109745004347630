import { useEffect, useState } from "react";
import DynamicFormInputs from "../../DynamicFormInputs";
import { isJson } from "../../../Utils/Common/Requisition";
import { CrossIcon } from "../../Icons";
import { t } from "i18next";
const Icd10Section = (props: any) => {
  const [diagnosisCode, setDiagnosisCode] = useState<any>([]);
  useEffect(() => {
    convertPrefilledIcdDataToJson();
  }, [props]);

  const [checkboxState, setCheckboxState] = useState<any>(() => {
    if (props?.Inputs && props?.index !== undefined) {
      const newArrayState = props.Inputs[props.index].fields
        .filter(
          (innerProps: any) => innerProps?.systemFieldName === "ICDPanels"
        )
        .flatMap((innerProps: any) =>
          innerProps.panels.flatMap((panel: any) =>
            panel.testOptions.map((innerOption: any) => ({
              testCode: innerOption.testCode,
              isChecked: innerOption.isSelected,
              description: innerOption.testName,
            }))
          )
        );
      return newArrayState;
    }
    return [];
  });

  const convertPrefilledIcdDataToJson = () => {
    let fieldIndex = props?.Inputs[props?.index].fields.findIndex(
      (icdPanelsInfo: any) => icdPanelsInfo?.systemFieldName == "ICDPanels"
    );
    let defaultVal =
      props?.Inputs[props?.index]?.fields[fieldIndex]?.defaultValue;
    let result = isJson(defaultVal);
    let parsedIcd10Codes = defaultVal;
    if (result) {
      parsedIcd10Codes = JSON?.parse(defaultVal);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    Array?.isArray(parsedIcd10Codes)
      ? setDiagnosisCode([...parsedIcd10Codes])
      : null;
  };
  const removeDiagnosisCode = (Code: string) => {
    let diagnosisCodeArrCopy = [...diagnosisCode];
    let filteredDiagnosisCodeArrCopy = diagnosisCodeArrCopy.filter(
      (items: any) => items?.Code != Code
    );
    setDiagnosisCode(filteredDiagnosisCodeArrCopy);
    let inputsCopy = [...props?.Inputs];
    let index = props?.index;

    if (index) {
      let icdPanelIndex = inputsCopy[index]?.fields?.findIndex(
        (elem: any) => elem?.systemFieldName === "ICDPanels"
      );
      inputsCopy[index].fields[icdPanelIndex].defaultValue = inputsCopy[
        index
      ].fields[icdPanelIndex]?.defaultValue?.filter(
        (icdData: any) => icdData.Code != Code
      );
    }
    setCheckboxState((prevState: any) => {
      const newState = prevState.map((item: any) => {
        if (item.testCode === Code) {
          return { ...item, isChecked: false };
        }
        return item;
      });
      return newState;
    });
  };

  //in-case of edit and error if it doesnt work remove it
  const combinedArray = [
    ...diagnosisCode.map((item: any) => ({
      code: item.Code,
      description: item.Description,
      fromDiagnosisCode: true,
    })),
    ...checkboxState
      .filter((item: any) => item.isChecked)
      .map((item: any) => ({
        code: item.testCode,
        description: item.description,
        fromDiagnosisCode: false,
      })),
  ];
  const uniqueCodes = new Set();
  const uniqueCombinedArray = combinedArray.filter((item: any) => {
    if (!uniqueCodes.has(item.code)) {
      uniqueCodes.add(item.code);
      return true;
    }
    return false;
  });
  return (
    <>
      {props?.Section?.fields.map((field: any, index: number) => (
        <>
          <DynamicFormInputs
            uiType={field?.uiType}
            label={field?.displayFieldName}
            disabled={field.disabled}
            defaultValue={field?.defaultValue ?? ""}
            displayType={field?.displayType}
            sectionDisplayType={props?.Section?.displayType}
            visible={field?.visible}
            required={field?.required}
            RadioOptions={
              field?.uiType === "RadioButton" ||
              field?.uiType === "CheckBoxList" ||
              field?.uiType === "DropDown"
                ? field?.options
                : ""
            }
            panels={field?.panels ?? []}
            formData={props?.formData}
            setFormData={props?.setFormData}
            formState={props?.formState}
            setFormState={props?.setFormState}
            index={props?.index}
            fieldIndex={index}
            Inputs={props?.Inputs}
            setInputs={props?.setInputs}
            sysytemFieldName={field?.systemFieldName ?? "undefined"}
            //value={props.formData.field?.sysytemFieldName}
            //setShowHideFields={setShowHideFields}
            isDependent={false}
            controlId={field?.controlId}
            dependenceyControls={props?.Section?.dependencyControls}
            //RemoveFields={RemoveFields}
            searchID={field?.searchID}
            isDependency={false}
            isShown={props.isShown}
            setIsShown={props.setIsShown}
            //masonryRef={props?.masonryRef}

            removeUi={field?.removeUi ? field?.removeUi : false}
            recursiveDependencyControls={
              field?.showDep ? field?.dependencyControls : false
            }
            showRecursiveDep={field?.showDep ? field?.showDep : false}
            section={props?.Section}
            pageId={props?.pageId}
            repeatFields={field?.repeatFields}
            repeatDependencyControls={field?.repeatDependencyControls}
            repeatFieldsState={field?.repeatFieldsState}
            repeatDependencyControlsState={field?.repeatDependencyControlsState}
            fieldLength={props?.Section?.fields}
            sectionName={props?.Section?.sectionName}
            field={field}
            infectiousData={props.infectiousData}
            setInfectiousData={props.setInfectiousData}
            mask={field.mask}
            enableRule={field.enableRule}
            errorFocussedInput={props?.errorFocussedInput}
            setInfectiousDataInputsForValidation={
              props?.setInfectiousDataInputsForValidation
            }
            setInputsForValidation={props?.setInputsForValidation}
            checkboxState={checkboxState}
            setCheckboxState={setCheckboxState}
          />
        </>
      ))}
      <h6 className="text-primary mb-3">{t("Diagnosis Code(s)")}</h6>
      <div className=" col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <table
          className="table table-cutome-expend table-bordered table-head-bg table-bg table-head-custom table-vertical-center border-1 mb-1"
          id="Icd 10 codes"
        >
          <thead className="rounded bg-secondary mt-2 mb-2">
            <tr>
              <th className="text-center text-muted">{t("Actions")}</th>
              <th className="text-center text-muted">{t("Icd 10 codes")}</th>
              <th className="text-muted text-center">{t("Description")}</th>
            </tr>
          </thead>
          <tbody>
            {/* {Array.isArray(diagnosisCode) &&
              diagnosisCode?.map((items: any) => (
                <>
                  <tr>
                    <td
                      onClick={() => {
                        removeDiagnosisCode(items.Code);
                      }}
                      style={{ cursor: "pointer" }}
                      className="text-center"
                    >
                      <CrossIcon />
                    </td>
                    <td>
                      <a href="# " className="text-gray-800 text-center p-3">
                        {items?.Code}
                      </a>
                    </td>
                    <td>
                      <a href="# " className="text-gray-800 p-3 ">
                        {items?.Description}
                      </a>
                    </td>
                  </tr>
                </>
              ))} */}

            {uniqueCombinedArray.map((item, index) => (
              <tr key={index}>
                <td
                  onClick={() => {
                    removeDiagnosisCode(item.code);
                  }}
                  style={{ cursor: "pointer" }}
                  className="text-center"
                >
                  <CrossIcon />
                </td>
                <td>
                  <a href="# " className="text-gray-800 text-center p-3">
                    {t(item.code)}
                  </a>
                </td>
                <td>
                  <a href="# " className="text-gray-800 p-3">
                    {t(item.description)}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Icd10Section;
